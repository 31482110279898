import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Row, Col, Input } from 'antd';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';


import style from "./AddStructureModal.module.css";
import { StructureDataType, StructureLevelDataType } from "../../../GlobalVariable";
import axios from "axios";

import * as myConstant from "../../../GlobalVariable";


const { TextArea } = Input;

function AddStructureModal({
    structureLevel,
    button,
    structureNameOld,
    structureLevelOld,
    structureIdOld,
    descriptionOld,
    editMode,
    editStructureLevel,
}: {
    structureLevel:
    StructureLevelDataType[], button: JSX.Element,
    structureNameOld?: string | null;
    structureLevelOld?: string | null;
    structureIdOld?: string | null;
    descriptionOld?: string | null;
    editMode?: boolean | null;
    editStructureLevel?: boolean | null;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleOpen = () => onOpen();

    const [structureForm, setStructureForm] = useState({
        structureLevelId: null,
        parentId: null,
        structureName: structureNameOld,
        description: descriptionOld,
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStructureForm({ ...structureForm, [e.target.name]: e.target.value });
    };
    const [apiStructureData, setApiStructureData] = useState<{ data: StructureDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );


    const [structureLevels, setStructureLevels] = useState<MenuProps["items"]>([]);
    useEffect(() => {
        var items: MenuProps["items"] = [];
        for (let index = 0; index < structureLevel.length; index++) {
            const element = structureLevel[index];
            const data = {
                label: <button onClick={(e) => { handleMenuClick(element) }}> {element.name}</button>
                ,
                key: element.id,
            };
            items.push(data);
        }
        setStructureLevels(items);
    }, [structureLevel]);

    const [selectedStructureLevel, setSelectedStructureLevel] = useState<any>(null);
    const [selectedParentStructure, setSelectedParentStructure] = useState<any>(null);

    const handleMenuClick = (value: any) => {
        // e.key will contain the key of the clicked menu item
        // console.log(value);
        setSelectedStructureLevel(value);
        setStructureForm({ ...structureForm, structureLevelId: value.id })
    };

    const [structures, setStructures] = useState<MenuProps["items"]>([]);

    useEffect(() => {
        setApiStructureData({ ...apiStructureData, loading: true });
        if (selectedStructureLevel !== null) {
            axios.get(myConstant.BASE_URL + "structure-level/get-one-up/" + selectedStructureLevel.id + "/",
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var items: MenuProps["items"] = [];
                    for (let index = 0; index < response.data.result.structures.length; index++) {
                        const element = response.data.result.structures[index];
                        if (element.name !== structureNameOld) {

                            const data = {
                                label: <button onClick={(e) => { handleParentStructureClick(element) }}> {element.name}</button>
                                ,
                                key: element.id,
                            };
                            items.push(data);
                        }
                    }
                    setStructures(items);
                }
            }).catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    if (error.response.data.error != null) {
                        alert(error.response.data.error);
                    }
                    else {
                        alert(error);
                    }
                }
                else {
                    alert(error);
                }
            });
        }
    }, [selectedStructureLevel])


    useEffect(() => {
        if (structureIdOld !== null) {
            for (let index = 0; index < structureLevel.length; index++) {
                const element = structureLevel[index];
                if (element.name === structureLevelOld) {
                    handleMenuClick(element);
                }
            }
        }
    }, [structureLevel, structureIdOld]);

    const handleParentStructureClick = (value: any) => {
        // console.log(value)
        setSelectedParentStructure(value);
        setStructureForm({ ...structureForm, parentId: value.id })
    };


    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(structureForm);

        axios
            .post(myConstant.BASE_URL +
                "management-structure/add-new/",
                {
                    "name": structureForm.structureName,
                    "description": structureForm.description,
                    "level_id": structureForm.structureLevelId,
                    "parentId": structureForm.parentId,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("Successfully")) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
    }

    const handleSubmitEdit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(structureForm);

        axios
            .put(myConstant.BASE_URL +
                "management-structure/edit-one/",
                {
                    "id": structureIdOld,
                    "name": structureForm.structureName,
                    "description": structureForm.description,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.status.includes("successfully")) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
    }

    const handleSubmitEditStructurePosition = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(structureForm);

        axios
            .put(myConstant.BASE_URL +
                "management-structure/modify-structure-position",
                {
                    "structure_id": structureIdOld,
                    "parent_id": structureForm.parentId,
                    "level_id": structureForm.structureLevelId
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.status.includes("successfully")) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
    }
    return (
        <div>
            <Row justify={"end"}>
                <Col>
                    <div className='p-2'>
                        {React.cloneElement(button, { onClick: () => handleOpen() })}
                    </div>
                </Col>
            </Row>

            <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose} isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1" style={{
                                borderBottom: "2px red solid",
                                textAlign: "center"
                            }}>
                                {
                                    editMode === true ?
                                        "Edit " + structureNameOld + " Details" :
                                        editStructureLevel ? "Edit " + structureNameOld + " Structure Level"
                                            : "Register Structure"
                                }
                            </ModalHeader>
                            <ModalBody>
                                {editMode === false || editStructureLevel === true ?
                                    <>
                                        <div className="p-2" >
                                            <Row align={"middle"} justify={"space-around"} >
                                                <Col span={8}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Structure Level
                                                    </p>
                                                </Col>
                                                <Col span={14}>
                                                    <Dropdown
                                                        menu={{ items: structureLevels }}
                                                        trigger={["click"]}
                                                        overlayStyle={{ maxHeight: 250, overflow: "scroll" }}
                                                    >
                                                        <div className={style["level-selector"]}>
                                                            <Row
                                                                className="pr-2"
                                                                style={{ height: "100%" }}
                                                                align={"middle"}
                                                                justify={"end"}
                                                            >
                                                                {(selectedStructureLevel !== null) ?
                                                                    selectedStructureLevel.name
                                                                    : "Select Structure Level"
                                                                }
                                                                <DownOutlined />
                                                            </Row>
                                                        </div>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="p-2" >
                                            <Row align={"middle"} justify={"space-around"} >
                                                <Col span={8}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Parent Structure
                                                    </p>
                                                </Col>
                                                <Col span={14}>
                                                    <Dropdown
                                                        menu={{ items: structures }}
                                                        trigger={["click"]}
                                                        overlayStyle={{ maxHeight: 250, overflow: "scroll" }}
                                                    >
                                                        <div className={style["level-selector"]}>
                                                            <Row
                                                                className="pr-2"
                                                                style={{ height: "100%" }}
                                                                align={"middle"}
                                                                justify={"end"}
                                                            >
                                                                {(selectedParentStructure !== null) ?
                                                                    selectedParentStructure.name
                                                                    : "Select Parent Structure"
                                                                }
                                                                <DownOutlined />
                                                            </Row>
                                                        </div>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }

                                {editStructureLevel === false || editMode === true ?
                                    <>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"space-around"}>
                                                <Col span={8}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Structure Name
                                                    </p>
                                                </Col>
                                                <Col span={14}>
                                                    <Input
                                                        name="structureName"
                                                        showCount
                                                        maxLength={200}
                                                        onChange={onChange}
                                                        value={structureForm.structureName!}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="p-2">
                                            <Row justify={"space-around"}>
                                                <Col span={8}>
                                                    Description
                                                </Col>
                                                <Col span={14}>
                                                    <TextArea
                                                        name="description"
                                                        placeholder="Description"
                                                        allowClear
                                                        onChange={onChange}
                                                        value={structureForm.description!}
                                                        style={{ height: 120, resize: 'none' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </> : <></>
                                }


                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                {
                                    editMode === true ?
                                        <>
                                            <Button
                                                color="primary"
                                                // onPress={onClose}
                                                onClick={(e) => handleSubmitEdit(e)}
                                            >
                                                Update
                                            </Button>
                                        </> : editStructureLevel === true ?
                                            <>
                                                <Button
                                                    color="primary"
                                                    // onPress={onClose}
                                                    onClick={(e) => handleSubmitEditStructurePosition(e)}
                                                >
                                                    Update
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    color="primary"
                                                    // onPress={onClose}
                                                    onClick={(e) => handleSubmitClick(e)}
                                                >
                                                    Save
                                                </Button>
                                            </>
                                }

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default AddStructureModal