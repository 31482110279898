import React from "react";
export const EmployeeIcon = ({ color, ...props }: { color: string, props?: any }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
<path d="M13.4484 15.0316C12.0006 15.0316 10.5853 14.6023 9.38147 13.7979C8.17766 12.9936 7.23941 11.8503 6.68535 10.5127C6.1313 9.17511 5.98634 7.70325 6.26879 6.28326C6.55124 4.86327 7.24843 3.55893 8.27219 2.53517C9.29594 1.51141 10.6003 0.814227 12.0203 0.531773C13.4403 0.24932 14.9121 0.394285 16.2497 0.948338C17.5873 1.50239 18.7306 2.44065 19.535 3.64446C20.3393 4.84827 20.7686 6.26356 20.7686 7.71137C20.7686 9.65282 19.9974 11.5148 18.6246 12.8876C17.2518 14.2604 15.3898 15.0316 13.4484 15.0316ZM13.4484 2.56628C12.4142 2.56628 11.4033 2.87294 10.5434 3.44748C9.68359 4.02202 9.0134 4.83864 8.61765 5.79407C8.2219 6.7495 8.11835 7.80083 8.3201 8.81511C8.52186 9.82939 9.01985 10.7611 9.7511 11.4923C10.4824 12.2236 11.414 12.7216 12.4283 12.9233C13.4426 13.1251 14.4939 13.0215 15.4493 12.6258C16.4048 12.23 17.2214 11.5598 17.7959 10.7C18.3705 9.84011 18.6771 8.82918 18.6771 7.79503C18.6771 7.10838 18.5419 6.42846 18.2791 5.79407C18.0164 5.15969 17.6312 4.58328 17.1457 4.09774C16.6601 3.61221 16.0837 3.22706 15.4493 2.96429C14.815 2.70152 14.135 2.56628 13.4484 2.56628ZM19.2732 16.297C13.6141 15.0236 7.69365 15.6369 2.41572 18.0434C1.68986 18.3901 1.07743 18.9359 0.649759 19.6172C0.222086 20.2985 -0.00323452 21.0873 3.50842e-05 21.8918V28.114C3.50813e-05 28.2513 0.0270844 28.3873 0.0796383 28.5142C0.132192 28.641 0.209221 28.7563 0.306328 28.8534C0.403435 28.9505 0.518718 29.0276 0.645594 29.0801C0.77247 29.1327 0.908456 29.1597 1.04579 29.1597C1.18312 29.1597 1.3191 29.1327 1.44598 29.0801C1.57285 29.0276 1.68814 28.9505 1.78524 28.8534C1.88235 28.7563 1.95938 28.641 2.01193 28.5142C2.06449 28.3873 2.09154 28.2513 2.09154 28.114V21.8918C2.08243 21.4846 2.19237 21.0837 2.40785 20.7382C2.62334 20.3927 2.93501 20.1176 3.30461 19.9467C6.48397 18.4786 9.9465 17.7254 13.4484 17.7401C15.4105 17.7376 17.366 17.9693 19.2732 18.4303V16.297ZM19.4196 26.2421H25.8405V27.7061H19.4196V26.2421Z" 
fill={color}
/>
<path d="M30.9543 20.0303H25.5478V22.1218H29.9086V30.8747H15.0903V22.1218H21.6785V22.561C21.6785 22.8383 21.7887 23.1043 21.9848 23.3004C22.1809 23.4966 22.4469 23.6067 22.7243 23.6067C23.0016 23.6067 23.2676 23.4966 23.4637 23.3004C23.6598 23.1043 23.77 22.8383 23.77 22.561V18.493C23.77 18.2157 23.6598 17.9497 23.4637 17.7536C23.2676 17.5574 23.0016 17.4473 22.7243 17.4473C22.4469 17.4473 22.1809 17.5574 21.9848 17.7536C21.7887 17.9497 21.6785 18.2157 21.6785 18.493V20.0303H14.0445C13.7672 20.0303 13.5012 20.1404 13.3051 20.3366C13.109 20.5327 12.9988 20.7987 12.9988 21.076V31.9205C12.9988 32.1978 13.109 32.4638 13.3051 32.6599C13.5012 32.856 13.7672 32.9662 14.0445 32.9662H30.9543C31.2317 32.9662 31.4977 32.856 31.6938 32.6599C31.8899 32.4638 32.0001 32.1978 32.0001 31.9205V21.076C32.0001 20.7987 31.8899 20.5327 31.6938 20.3366C31.4977 20.1404 31.2317 20.0303 30.9543 20.0303Z" 
fill={color}
/>
</svg>


);
