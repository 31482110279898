// import axios from "axios";
import React, { useState, useEffect } from 'react';
import {
    // Table, Row, Col, Image, Avatar, 
    Tabs
} from 'antd';
// import type { ColumnsType } from 'antd/es/table';
// import { Spacer, Button } from "@nextui-org/react";

import type { TabsProps } from 'antd';
import { getUserType } from "../../jwtDecoder";

// import editIcon from "../../assets/edit-icon.png";
// import deleteIcon from "../../assets/delete-icon.png";
import InternalLayout from '../../layout/InternalLayout';
// import { EmployeesPerformanceDataType, StructurePerformanceDataType, OneEntityPerformanceDataType } from '../../GlobalVariable';
import EmployeesPerformanceReport from "./components/EmployeesPerformanceReport";
import StructurePerformanceReport from "./components/StructurePerformanceReport";
import TaskReport from "./components/TaskReport";



function ReportPage() {
    const onChange = (key: string) => {
        // console.log(key);
    };

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {
            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    let items: TabsProps['items'] = [
        {
            key: "3",
            label: "Task Level Report",
            children: <TaskReport />
        },
        {
            key: '1',
            label: 'Employees Performance Report',
            children: <EmployeesPerformanceReport />,
        },


    ];
    if (["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) {
        items.push({
            key: '2',
            label: 'Management Structure Performance Report',
            children: <StructurePerformanceReport />,
        })
    }


    return (
        <InternalLayout title="Report">
            <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
            />
        </InternalLayout>
    )
}

export default ReportPage