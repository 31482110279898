import React from "react";

export const CompletedTasksIcon = ({ ...props }: { props?: any }) => (
    <svg
        width="35"
        height="35"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22.5"
            cy="22.5"
            r="22.5"
            fill="#28CC9E"
            fillOpacity="0.2"
        />
        <g
            clipPath="url(#clip0_743_4653)">
            <path
                d="M30.5266 14.2999H25.7266C25.5348 13.7296 25.1687 13.2339 24.6801 12.8828C24.1915 12.5317 23.605 12.3428 23.0033 12.3428C22.4016 12.3428 21.8151 12.5317 21.3265 12.8828C20.8379 13.2339 20.4718 13.7296 20.28 14.2999H15.6666C15.5034 14.2763 15.337 14.2875 15.1784 14.333C15.0198 14.3784 14.8726 14.4569 14.7466 14.5634C14.6206 14.6699 14.5187 14.8018 14.4474 14.9506C14.3762 15.0994 14.3373 15.2616 14.3333 15.4265V32.5065C14.3342 32.6554 14.3644 32.8026 14.4221 32.9397C14.4799 33.0769 14.5641 33.2013 14.67 33.306C14.7758 33.4106 14.9013 33.4933 15.0391 33.5495C15.1769 33.6056 15.3245 33.6341 15.4733 33.6332H30.5266C30.6755 33.6341 30.823 33.6056 30.9609 33.5495C31.0987 33.4933 31.2241 33.4106 31.33 33.306C31.4359 33.2013 31.5201 33.0769 31.5778 32.9397C31.6356 32.8026 31.6658 32.6554 31.6666 32.5065V15.4265C31.6658 15.2777 31.6356 15.1305 31.5778 14.9933C31.5201 14.8562 31.4359 14.7317 31.33 14.6271C31.2241 14.5225 31.0987 14.4397 30.9609 14.3836C30.823 14.3274 30.6755 14.299 30.5266 14.2999ZM18.5266 16.2999C18.5266 16.1231 18.5969 15.9535 18.7219 15.8285C18.8469 15.7034 19.0165 15.6332 19.1933 15.6332H21.4866V15.2132C21.4866 14.8039 21.6492 14.4113 21.9387 14.1219C22.2281 13.8325 22.6207 13.6699 23.03 13.6699C23.4393 13.6699 23.8319 13.8325 24.1213 14.1219C24.4107 14.4113 24.5733 14.8039 24.5733 15.2132V15.6665H26.8666C27.0435 15.6665 27.213 15.7368 27.3381 15.8618C27.4631 15.9868 27.5333 16.1564 27.5333 16.3332V17.5332H18.5L18.5266 16.2999ZM28.2066 22.4532L22.1266 28.5332L18.6333 25.0399C18.4565 24.8631 18.3572 24.6232 18.3572 24.3732C18.3572 24.1231 18.4565 23.8833 18.6333 23.7065C18.8101 23.5297 19.0499 23.4304 19.3 23.4304C19.55 23.4304 19.7898 23.5297 19.9666 23.7065L22.14 25.8799L26.9 21.1199C27.0768 20.9431 27.3166 20.8437 27.5666 20.8437C27.8167 20.8437 28.0565 20.9431 28.2333 21.1199C28.4101 21.2967 28.5095 21.5365 28.5095 21.7865C28.5095 22.0366 28.4101 22.2764 28.2333 22.4532H28.2066Z"
                fill="#28CC9E"
            />
        </g>
        <defs>
            <clipPath
                id="clip0_743_4653"
            >
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(11 11)"
                />
            </clipPath>
        </defs>
    </svg>

);
