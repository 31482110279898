import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import './App.css';
import LoginPage from './views/LoginPage/LoginPage';
import ResetPasswordPage from './views/ResetPasswordPage/ResetPasswordPage';
import CreateNewPasswordPage from './views/CreateNewPasswordPage/CreateNewPasswordPage';
import StructureLevelPage from './views/ManagementStructure/StructureLevelPage/StructureLevelPage';
import DashboardPage from './views/DashboardPage/DashboardPage';
import StructurePage from './views/ManagementStructure/StructurePage/StructurePage';
import EmployeePage from './views/EmployeePage/EmployeePage';
import ReportPage from './views/ReportPage/ReportPage';

function App() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        // errorElement: <PageUnderConstruction />,
        element: <DashboardPage />,
      },
      {
        path: "login",
        element: <LoginPage />
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />
      },
      {
        path: "create-password",
        element: <CreateNewPasswordPage />
      },
      {
        path: "structure",
        children: [
          { path: "", element: <StructurePage /> },
          { path: "level", element: <StructureLevelPage /> },
        ]
      },
      {
        path: "employee",
        element: <EmployeePage />,
      },
      {
        path: "report",
        element: <ReportPage />,
      },
    ],
    // {
    //   basename: "/rl-admin",
    // }
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
