import axios from "axios";
import React, { useState, useEffect, ChangeEvent } from "react";

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Row, Col, Input, Image, Dropdown, message } from 'antd';
import type { MenuProps } from 'antd';

import { DownOutlined, FileImageOutlined } from '@ant-design/icons';

import * as myConstant from "../../../GlobalVariable";
import style from "./AddEmployeeModal.module.css";
import { StructureDataType } from '../../../GlobalVariable';


// const { TextArea } = Input;

function AddEmployeeModal({
    incomingStructure,
    button,
    editMode,
    userIdOld,
    usernameOld,
    prefixOld,
    firstNameOld,
    lastNameOld,
    phoneNumberOld,
}: {
    incomingStructure: StructureDataType[],
    button: JSX.Element,
    editMode?: boolean | null;
    userIdOld?: string | null;
    usernameOld?: string | null;
    prefixOld?: string | null;
    firstNameOld?: string | null;
    lastNameOld?: string | null;
    phoneNumberOld?: string | null;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [messageApi, contextHolder] = message.useMessage();

    const handleOpen = () => onOpen();

    const [structures, setStructures] = useState<MenuProps["items"]>([]);
    const [selectedStructure, setSelectedStructure] = useState<any>(null);

    const [employeeForm, setEmployeeForm] = useState({
        structureId: "",
        username: usernameOld,
        prefix: prefixOld,
        firstName: firstNameOld,
        lastName: lastNameOld,
        email: "",
        phoneNumber: phoneNumberOld,
    });
    const [profilePic, setProfilePic] = useState<{ image: Blob | null, imageSrc: string }>({
        image: null,
        imageSrc: "",
    });

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setProfilePic({
                image: e.target.files[0],
                imageSrc: URL.createObjectURL(e.target.files[0])
            });
        }
    };
    const onClickImageInput = () => {
        const input = document.getElementById("selectProfilePic");
        input != null && input.click();
    };
    const onImageClear = () => {
        setProfilePic({ image: null, imageSrc: "" });
    };

    const PreviewImage = () => {
        if (profilePic.image !== null) {

            return (
                <Col>
                    <Image
                        width={150}
                        height={150}
                        src={
                            profilePic.imageSrc
                        }
                        alt="Default Image"
                    />
                    <Row
                    >
                        <Button
                            onClick={() => onImageClear()}
                        >
                            Clear Picture
                        </Button>
                    </Row>
                </Col>
            );
        } else {
            return (
                <>
                    <input
                        name="patient_image"
                        accept="image/*, */dicom,.dcm, image/dcm, */dcm, .dicom"
                        type="file"
                        id="selectProfilePic"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e)}
                    />
                    <Button
                        id="upload_button"
                        value="Upload"
                        name="upload_button"
                        onClick={(e) =>
                            onClickImageInput()
                        }
                    >
                        <FileImageOutlined />
                    </Button>
                </>
            );
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmployeeForm({ ...employeeForm, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        var items: MenuProps["items"] = [];
        for (let index = 0; index < incomingStructure.length; index++) {
            const element = incomingStructure[index];
            // console.log(element.isEditable, element.isEditable === "true");
            if (element.isEditable) {
                const data = {
                    label: <button onClick={(e) => { handleMenuClick(element) }}> {element.name}</button>
                    ,
                    key: element.id,
                };
                items.push(data);
            }
        }
        setStructures(items);
    }, [incomingStructure]);


    const handleMenuClick = (value: any) => {
        setSelectedStructure(value);
        setEmployeeForm({ ...employeeForm, structureId: value.id })
    };

    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("username", employeeForm.username!);
        formData.append("prefix", employeeForm.prefix!);
        formData.append("first-name", employeeForm.firstName!);
        formData.append("last-name", employeeForm.lastName!);
        formData.append("email", employeeForm.email);
        formData.append("phone-number", employeeForm.phoneNumber!);
        formData.append("profile-photo", profilePic.image as Blob);
        formData.append("structure-id", selectedStructure.id);

        axios.post(myConstant.BASE_URL + "account/add-new/",
            formData,
            {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        (sessionStorage.getItem("Token") !== null
                            ? sessionStorage.getItem("Token")
                            : localStorage.getItem("Token")),
                },
                withCredentials: true,
            }
        ).then(function (response) {
            if (response.status === 200) {
                if (response.data.result.includes("Successfully")) {
                    // successMessage(response.data.result);
                    window.location.reload();
                }
            }
        }).catch(function (error) {
            // console.log(error);
            // errorMessage(error);
            if (error.response.data.error != null) {
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            }
            else {
                alert(error);
            }
        });
    }

    const handleSubmitEdit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        axios
            .put(myConstant.BASE_URL +
                "account/edit-detail/",
                {
                    "id": userIdOld,
                    "prefix": employeeForm.prefix,
                    "first-name": employeeForm.firstName,
                    "last-name": employeeForm.lastName,
                    "phone-number": employeeForm.phoneNumber
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("successfully")) {
                        successMessage(response.data.result);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                errorMessage(error);
            });
    }

    // const successMessage = (message: string) => {
    //     messageApi.open({
    //         type: 'success',
    //         content: message,
    //     });
    // };

    // const errorMessage = (message: string) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: message,
    //     });
    // };


    const successMessage = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    return (
        <div>
            <Row justify={"end"}>
                <Col>
                    <div className='p-2'>
                        {React.cloneElement(button, { onClick: () => handleOpen() })}
                    </div>
                </Col>
            </Row>

            <Modal size="3xl" backdrop={"blur"} isOpen={isOpen} onClose={onClose} isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1" style={{
                                borderBottom: "2px red solid",
                                textAlign: "center"
                            }}>
                                {
                                    editMode === true ? "Update Account Details" : "Register Employee"
                                }
                            </ModalHeader>
                            <ModalBody>
                                {editMode === false ?
                                    <>
                                        <div className="p-2" >
                                            <Row align={"middle"} justify={"start"} >
                                                <Col span={4}>
                                                    <p style={{ marginBottom: 0, }}>
                                                        Structure
                                                    </p>
                                                </Col>
                                                <Col span={10}>
                                                    <Dropdown
                                                        menu={{ items: structures }}
                                                        trigger={["click"]}
                                                        overlayStyle={{ maxHeight: 250, overflow: "scroll" }}
                                                    >
                                                        <div className={style["level-selector"]}>
                                                            <Row
                                                                className="pr-2"
                                                                style={{ height: "100%" }}
                                                                align={"middle"}
                                                                justify={"end"}
                                                            >
                                                                {(selectedStructure !== null) ?
                                                                    selectedStructure.name
                                                                    : "Select Structure"
                                                                }
                                                                <DownOutlined />
                                                            </Row>
                                                        </div>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"start"}>
                                                <Col span={5}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Profile Picture
                                                    </p>
                                                </Col>
                                                <Col span={14}>
                                                    {PreviewImage()}
                                                </Col>
                                            </Row>
                                        </div>
                                    </> :
                                    null
                                }

                                {/* username and prefix */}
                                <Row>
                                    {editMode === false
                                        ?
                                        <Col span={14}>
                                            <div className="p-2">
                                                <Row align={"middle"} justify={"start"}>
                                                    <Col span={7}>
                                                        <p style={{ marginBottom: 0 }}>
                                                            Username
                                                        </p>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Input
                                                            name="username"
                                                            showCount
                                                            maxLength={200}
                                                            onChange={onChange}
                                                            value={employeeForm.username!}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        : null
                                    }

                                    <Col span={editMode ? 14 : 10}>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"start"}>
                                                <Col span={7}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Prefix
                                                    </p>
                                                </Col>
                                                <Col span={16}>
                                                    <Input
                                                        name="prefix"
                                                        showCount
                                                        maxLength={10}
                                                        onChange={onChange}
                                                        value={employeeForm.prefix!}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                {/* first name and last name */}
                                <Row>
                                    <Col span={12}>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"space-around"}>
                                                <Col span={7}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        First Name
                                                    </p>
                                                </Col>
                                                <Col span={16}>
                                                    <Input
                                                        name="firstName"
                                                        showCount
                                                        maxLength={200}
                                                        onChange={onChange}
                                                        value={employeeForm.firstName!}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"space-around"}>
                                                <Col span={7}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Last Name
                                                    </p>
                                                </Col>
                                                <Col span={16}>
                                                    <Input
                                                        name="lastName"
                                                        showCount
                                                        maxLength={200}
                                                        onChange={onChange}
                                                        value={employeeForm.lastName!}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                {/* email and phone number */}
                                <Row>
                                    {
                                        editMode === false ?
                                            <>
                                                <Col span={12}>
                                                    <div className="p-2">
                                                        <Row align={"middle"} justify={"space-around"}>
                                                            <Col span={7}>
                                                                <p style={{ marginBottom: 0 }}>
                                                                    Email Address
                                                                </p>
                                                            </Col>
                                                            <Col span={16}>
                                                                <Input
                                                                    name="email"
                                                                    showCount
                                                                    maxLength={200}
                                                                    onChange={onChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </>
                                            : null
                                    }
                                    <Col span={12}>
                                        <div className="p-2">
                                            <Row align={"middle"} justify={"space-around"}>
                                                <Col span={7}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        Phone Number
                                                    </p>
                                                </Col>
                                                <Col span={16}>
                                                    <Input
                                                        name="phoneNumber"
                                                        showCount
                                                        maxLength={200}
                                                        onChange={onChange}
                                                        value={employeeForm.phoneNumber!}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                {/* {contextHolder} */}
                            </ModalBody>
                            <ModalFooter className="justify-center">
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                {
                                    editMode === true ?
                                        <>
                                            <Button
                                                className="action-btn"
                                                onClick={(e) => handleSubmitEdit(e)}
                                            >
                                                Update Details
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Button
                                                className="action-btn"
                                                onClick={(e) => handleSubmitClick(e)}
                                            >
                                                Register Employee
                                            </Button>
                                        </>
                                }

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default AddEmployeeModal