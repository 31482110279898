import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { Table, Space, Input } from 'antd';
import { Button, Spacer } from "@nextui-org/react";

import * as myConstant from "../../../GlobalVariable";
import { TaskPerformanceDataType } from '../../../GlobalVariable';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";

import type { InputRef } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { getUserType } from "../../../jwtDecoder";

type DataIndex = keyof TaskPerformanceDataType;


function TaskReport() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);


    const [apiData, setApiData] = useState<{ data: TaskPerformanceDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {

            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            setApiData({ ...apiData, loading: true });

            const apiUrl = myConstant.BASE_URL + "summary/tasks-report/";
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfEmployee: TaskPerformanceDataType[] = [];
                    for (let index = 0; index < response.data.result.tasks.length; index++) {
                        const element = response.data.result.tasks[index];

                        const performanceReport: TaskPerformanceDataType = {
                            key: index + 1,
                            id: element.id,
                            title: element["title"],
                            memberCount: element["total-member"],
                            status: element["status"],
                            priority: element["priority"],
                            startDate: element["startDate"] + " " + element["startTime"],
                            dueDate: element["dueDate"] + " " + element["dueTime"],
                            assignedTo: element["assignedTo"],
                        }
                        listOfEmployee.push(performanceReport);
                    }
                    setApiData({ ...apiData, data: listOfEmployee, loaded: true, loading: false });
                }
            }).catch(function (error) {
                alert(error.response.data.error);
            });

        }
    }, [userType, authenticated]);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void, dataIndex: string) => {
        clearFilters();
        setSearchText("");
        setSearchedColumn("");
        if (dataIndex === "name" && searchInput.current) {
            searchInput.current.focus();
        }
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<TaskPerformanceDataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        color="primary"
                        variant="bordered"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        startContent={<SearchOutlined />}
                    >
                        Search
                    </Button>
                    <Button
                        color="warning"
                        variant="bordered"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                    >
                        Clear
                    </Button>
                    <Button
                        color="danger"
                        variant="bordered"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns: ColumnsType<TaskPerformanceDataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            width: "8%",
            align: "center",
            sorter: (a, b) => {
                if (a.key < b.key) {
                    return 1;
                } else if (a.key > b.key) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
            ...getColumnSearchProps('title'),
            sorter: (a, b) => {
                if (a.title < b.title) {
                    return 1;
                } else if (a.title > b.title) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            ...getColumnSearchProps('priority'),
            sorter: (a, b) => {
                if (a.priority < b.priority) {
                    return 1;
                } else if (a.priority > b.priority) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Members Count',
            dataIndex: 'memberCount',
            key: 'memberCount',
            ...getColumnSearchProps('memberCount'),
            sorter: (a, b) => {
                if (a.memberCount < b.memberCount) {
                    return 1;
                } else if (a.memberCount > b.memberCount) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status'),
            sorter: (a, b) => {
                if (a.status < b.status) {
                    return 1;
                } else if (a.status > b.status) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',

            ...getColumnSearchProps('startDate'),
            sorter: (a, b) => {
                if (a.startDate < b.startDate) {
                    return 1;
                } else if (a.startDate > b.startDate) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',

            ...getColumnSearchProps('dueDate'),
            sorter: (a, b) => {
                if (a.dueDate < b.dueDate) {
                    return 1;
                } else if (a.dueDate > b.dueDate) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },

    ];


    const [downloading, setDownloading] = useState(false);
    const handleExport = async () => {
        if (authenticated === true) {
            setDownloading(true);
            try {
                const apiUrl = myConstant.BASE_URL + "summary/export-report/report_task/";
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                }); // Replace with your API endpoint
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'task-report.Xlsx'; // Optional: Customize filename
                link.click();
                setDownloading(false);
            } catch (error) {
                setDownloading(false);
                alert(error);
            }
        } else {
            window.location.reload();
        }
    };


    return (
        <>
            <button className="action-btn" style={{ padding: "10px" }} onClick={handleExport} disabled={downloading}>
                {downloading ? 'Downloading...' : 'Export CSV'}
            </button>
            < Spacer y={1} />
            <Table
                columns={columns}
                dataSource={apiData.data}
                scroll={{ x: 'calc(700px + 50%)', }}
                pagination={{ pageSize: 10 }}
                size={"small"}
            />
        </>
    )
}

export default TaskReport