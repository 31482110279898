import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { Table, Space, Input } from 'antd';
import { Button, Spacer } from "@nextui-org/react";

import * as myConstant from "../../../GlobalVariable";
import { StructurePerformanceDataType, TaskPerformanceDataType } from '../../../GlobalVariable';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";

import type { InputRef } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { getUserType } from "../../../jwtDecoder";

type DataIndex = keyof StructurePerformanceDataType;

function StructurePerformanceReport() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);


    const [apiData, setApiData] = useState<{ data: StructurePerformanceDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {

            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            setApiData({ ...apiData, loading: true });

            const apiUrl = myConstant.BASE_URL + "summary/groups-report/";
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfEmployee: StructurePerformanceDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const taskReport = element["task-report"];

                        const tasks = element["tasks"];
                        var listOfTasks: TaskPerformanceDataType[] = [];

                        for (let i = 0; i < tasks.length; i++) {
                            const taskData = tasks[i];
                            const task: TaskPerformanceDataType = {
                                key: i + 1,
                                id: taskData["id"],
                                title: taskData["title"],
                                memberCount: taskData["total-member"],
                                status: taskData["status"],
                                priority: taskData["priority"],
                                startDate: taskData["Start Date"],
                                dueDate: taskData["Due Date"],
                                assignedTo: [],
                            }
                            listOfTasks.push(task);
                        }

                        const performanceReport: StructurePerformanceDataType = {
                            key: index + 1,
                            id: element.id,
                            name: element.name,
                            supervisor: element.supervisor,
                            totalCount: taskReport["Total"],
                            assignedCount: taskReport["Unopened"],
                            canceledCount: taskReport["Canceled"],
                            completedCount: taskReport["Completed"],
                            inProgressCount: taskReport["In progress"],
                            pendingCount: taskReport["Pending"],
                            redoCount: taskReport["Redo"],
                            verifiedCount: taskReport["Verified"],
                            tasks: listOfTasks,
                        }
                        listOfEmployee.push(performanceReport);
                    }
                    setApiData({ ...apiData, data: listOfEmployee, loaded: true, loading: false });
                }
            }).catch(function (error) {
                // console.log(error);
                alert(error.response.data.error);
            });

        }
    }, [userType, authenticated]);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void, dataIndex: string) => {
        clearFilters();
        setSearchText("");
        setSearchedColumn("");
        if (dataIndex === "name" && searchInput.current) {
            searchInput.current.focus();
        }
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<StructurePerformanceDataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        color="primary"
                        variant="bordered"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        startContent={<SearchOutlined />}
                    >
                        Search
                    </Button>
                    <Button
                        color="warning"
                        variant="bordered"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                    >
                        Clear
                    </Button>
                    <Button
                        color="danger"
                        variant="bordered"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<StructurePerformanceDataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            width: "6%",
            align: "center",
            sorter: (a, b) => {
                if (a.key < b.key) {
                    return 1;
                } else if (a.key > b.key) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: "13%",
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                if (a.key < b.key) {
                    return 1;
                } else if (a.key > b.key) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Supervisor',
            dataIndex: 'supervisor',
            key: 'supervisor',
            width: "12%",
            ...getColumnSearchProps('supervisor'),
        },
        {
            title: 'Total',
            dataIndex: 'totalCount',
            key: 'totalCount',
            align: "center",
            sorter: (a, b) => a.totalCount - b.totalCount,
        },
        {
            title: 'Assigned',
            dataIndex: 'assignedCount',
            key: 'assignedCount',
            align: "center",
            sorter: (a, b) => a.assignedCount - b.assignedCount,
        },
        {
            title: 'pending',
            dataIndex: 'pendingCount',
            key: 'pendingCount',
            align: "center",
            sorter: (a, b) => a.pendingCount - b.pendingCount,
        },
        {
            title: 'In Progress',
            dataIndex: 'inProgressCount',
            key: 'inProgressCount',
            align: "center",
            sorter: (a, b) => a.inProgressCount - b.inProgressCount,
        },
        {
            title: 'Completed',
            dataIndex: 'completedCount',
            key: 'completedCount',
            align: "center",
            sorter: (a, b) => a.completedCount - b.completedCount,
        },
        {
            title: 'Canceled',
            dataIndex: 'canceledCount',
            key: 'canceledCount',
            align: "center",
            sorter: (a, b) => a.canceledCount - b.canceledCount,
        },
        {
            title: 'Redo',
            dataIndex: 'redoCount',
            key: 'redoCount',
            align: "center",
            sorter: (a, b) => a.redoCount - b.redoCount,
        },
        {
            title: 'Verified',
            dataIndex: 'verifiedCount',
            key: 'verifiedCount',
            align: "center",
            sorter: (a, b) => a.verifiedCount - b.verifiedCount,
        },

    ];

    const customExpandedRowRender = (tasks: Array<TaskPerformanceDataType>) => {
        const internalColumns: ColumnsType<TaskPerformanceDataType> = [
            { title: "No", dataIndex: "key", key: "key" },
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                sorter: (a, b) => {
                    if (a.title < b.title) {
                        return 1;
                    } else if (a.title > b.title) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },

            {
                title: "Priority",
                dataIndex: "priority",
                key: "priority",
                sorter: (a, b) => {
                    if (a.priority < b.priority) {
                        return 1;
                    } else if (a.priority > b.priority) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
            {
                title: 'Members Count',
                dataIndex: 'memberCount',
                key: 'memberCount',
                // ...getColumnSearchProps('memberCount'),
                sorter: (a, b) => {
                    if (a.memberCount < b.memberCount) {
                        return 1;
                    } else if (a.memberCount > b.memberCount) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: (a, b) => {
                    if (a.status < b.status) {
                        return 1;
                    } else if (a.status > b.status) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
            {
                title: "Start Date",
                dataIndex: "startDate",
                key: "startDate",
                sorter: (a, b) => {
                    if (a.startDate < b.startDate) {
                        return 1;
                    } else if (a.startDate > b.startDate) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
            {
                title: "Due Date",
                dataIndex: "dueDate",
                key: "dueDate",
                sorter: (a, b) => {
                    if (a.dueDate < b.dueDate) {
                        return 1;
                    } else if (a.dueDate > b.dueDate) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
        ];

        return (
            <Table
                size="small"
                // scroll={{ x: 'calc(700px + 50%)', }}
                columns={internalColumns}
                dataSource={tasks}
                pagination={false}
            />
        );
    }


    const [downloading, setDownloading] = useState(false);
    const handleExport = async () => {
        if (authenticated === true) {
            setDownloading(true);
            try {
                const apiUrl = myConstant.BASE_URL + "summary/export-report/report_structure/";
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                }); // Replace with your API endpoint
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'structure-performance.Xlsx'; // Optional: Customize filename
                link.click();
                setDownloading(false);
            } catch (error) {
                setDownloading(false);
                alert(error);
            }
        } else {
            window.location.reload();
        }
    };

    return (
        <>
            <button className="action-btn" style={{ padding: "10px" }} onClick={handleExport} disabled={downloading}>
                {downloading ? 'Downloading...' : 'Export CSV'}
            </button>
            < Spacer y={1} />
            <Table
                columns={columns}
                dataSource={apiData.data}
                scroll={{ x: 'calc(1200px + 60%)', }}
                expandable={{
                    expandedRowRender: (record) => <div style={{ paddingInline: "40px" }}>
                        {customExpandedRowRender(record.tasks)}
                    </div>,
                    rowExpandable: (record) => record.tasks.length !== 0,
                }}
                pagination={{ pageSize: 10 }}
                bordered={true}
                size={"small"}
            />
        </>
    )
}

export default StructurePerformanceReport