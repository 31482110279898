import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Table, Row, Col } from 'antd';
import { Spacer, Button } from "@nextui-org/react";
import type { ColumnsType } from 'antd/es/table';

import InternalLayout from '../../../layout/InternalLayout';
import editIcon from "../../../assets/edit-icon.png";
import deleteIcon from "../../../assets/delete-icon.png";
import AddStructureLevelModal from '../../../components/modals/AddStructureLevelModal/AddStructureLevelModal';
import * as myConstant from "../../../GlobalVariable";
import { StructureLevelDataType } from "../../../GlobalVariable";
import DeleteModal from "../../../components/modals/DeleteModal/DeleteModal";
import { getUserType } from "../../../jwtDecoder";
import style from "./StructureLevelPage.module.css";

// class CustomError extends Error {
//     constructor(message: string) {
//         super(message);

//         // Set the prototype explicitly.
//         Object.setPrototypeOf(this, CustomError.prototype);
//     }
// }

function StructureLevelPage() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);


    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {

            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    const [apiData, setApiData] = useState<{ data: StructureLevelDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );
    const [assignableStructure, setAssignableStructure] = useState<{ data: StructureLevelDataType[] }>({ data: [] })


    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            setApiData({ ...apiData, loading: true });
            var apiUrl: string = myConstant.BASE_URL + "structure-level/get-org-structure/full/"
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "structure-level/get-all/"
            }
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfStructure: StructureLevelDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const structure = {
                            key: index + 1,
                            name: element.name,
                            id: element.id,
                            title: element.title,
                            parent: element.parent,
                            description: element.description,
                        }
                        listOfStructure.push(structure);
                    }
                    setApiData({ ...apiData, data: listOfStructure, loaded: true, loading: false });
                }
            }).catch(function (error) {

                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
        }
    }, [userType]);

    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            var apiUrl: string = myConstant.BASE_URL + "structure-level/get-org-structure/null/"
            // console.log(' ["Owner", "SuperAdmin", "Admin"].indexOf(userType!)', userType, ["Owner", "SuperAdmin", "Admin"].indexOf(userType!))
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "structure-level/get-all/"
            }
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfStructure: StructureLevelDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const structure = {
                            key: index + 1,
                            name: element.name,
                            id: element.id,
                            title: element.title,
                            parent: element.parent,
                            description: element.description,
                        }
                        listOfStructure.push(structure);
                    }
                    setAssignableStructure({ data: listOfStructure });
                }
            }).catch(function (error) {

                // console.log(error);
                if (error.response.data.error != null) {
                    // alert(error.response.data.error);
                }
                else {
                    // alert(error);
                }
            });
        }
    }, [authenticated, userType]);


    const columns: ColumnsType<StructureLevelDataType> = [
        { title: 'No', dataIndex: 'key', key: 'key' },
        { title: 'Structure Level', dataIndex: 'name', key: 'name' },
        { title: 'Head Title', dataIndex: 'title', key: 'title' },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) =>
                ["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0 ?
                    <Row align={"middle"} justify={"center"}>
                        <Col>
                            {/* <a href="/#">
                                <img
                                    src={editIcon}
                                    alt="edit"
                                    width={20}
                                />
                            </a> */}
                            <AddStructureLevelModal
                                structureLevel={assignableStructure.data}
                                structureNameOld={record.name}
                                structureLevelOldId={record.id}
                                parentOld={record.parent}
                                headTitleOld={record.title}
                                descriptionOld={record.description}
                                editMode={true}
                                button={
                                    // <Button
                                    //     isIconOnly
                                    //     key="blur"
                                    //     variant="light"
                                    // >
                                    //     <img
                                    //         src={editIcon}
                                    //         alt="edit"
                                    //         width={20}
                                    //     />
                                    // </Button>
                                    <button >
                                        <img
                                            src={editIcon}
                                            alt="edit"
                                            width={20}
                                        />
                                    </button>
                                }
                            />
                        </Col>
                        <Spacer x={1}></Spacer>
                        <Col>
                            <DeleteModal
                                title={"Structure Level"}
                                incomingDataId={record.id}
                                incomingApiUrl={"structure-level/delete-one/"}
                                button={
                                    <button >
                                        <img
                                            src={deleteIcon}
                                            alt="delete-structure-level"
                                            width={20}
                                        />
                                    </button>
                                }
                            />
                        </Col>
                    </Row> : <></>,
        },
    ];


    return (
        <InternalLayout title='Structure Levels'>
            <>
                {(userType !== undefined && ["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) ?
                    <AddStructureLevelModal
                        structureLevel={assignableStructure.data}
                        button={
                            <Button
                                key="blur"
                                variant="flat"
                                // color="warning"
                                // onPress={() => handleOpen(b)}
                                className="capitalize action-btn"
                            >
                                Add Structure Level
                            </Button>
                        }
                    /> : null
                }

                <Spacer y={2} />

                <Table
                    className={style["structure-level-table"]}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) =>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: 800, marginRight: 10 }}>
                                            Parent Structure Level:
                                        </span>{record.parent}

                                    </p>
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 800 }}> Description: </span> {record.description}</p>
                                </Col>
                            </Row>,
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                        columnTitle: "Details",
                    }}
                    dataSource={apiData.data}
                    pagination={{ pageSize: 10 }}
                    bordered={true}

                />
            </>
        </InternalLayout>
    )
}

export default StructureLevelPage