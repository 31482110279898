import axios from "axios";
import React, { useState } from "react";
import { Spacer, Input, Button, Link as NextLink } from "@nextui-org/react";
import { Col, Row, Checkbox } from 'antd';
import { useNavigate } from "react-router-dom";
import * as myConstant from "../../GlobalVariable";
// import { Link } from 'react-router-dom';
import decodeJwtToken from "../../jwtDecoder";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import style from "./LoginPage.module.css";
import ExternalLayout from "../../layout/ExternalLayout";
import { MailIcon } from '../../components/Icons/MailIcon';
import { EyeSlashFilledIcon } from '../../components/Icons/EyeSlashFilledIcon';
import { EyeFilledIcon } from '../../components/Icons/EyeFilledIcon';

interface ILoginValue {
    username: string;
    password: string;
    remember_me: boolean;
}


function LoginPage() {
    const [isVisible, setIsVisible] = React.useState(false);
    const [checked, setChecked] = useState(true);
    const navigate = useNavigate();


    const [loginForm, handleLoginForm] = useState<ILoginValue>({
        username: "",
        password: "",
        remember_me: true,
    });

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        handleLoginForm({ ...loginForm, [e.target.name]: e.target.value });
    };

    const handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        axios
            .post(myConstant.BASE_URL + "session/login/",
                // {
                //     "username": loginForm.username,
                //     "password": loginForm.password,
                //     "remember_me": loginForm.remember_me,
                // },
                {
                    "username": loginForm.username,
                    "password": loginForm.password,
                    "remember_me": loginForm.remember_me,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                // console.log(response);
                if (response.status === 200) {
                    // console.log("state", response.data);
                    if (response.data !== undefined && response.data.result !== undefined) {
                        const jwtToken = response.data.result.access_token;
                        if (jwtToken !== undefined) {
                            // console.log(loginForm.remember_me, "result", jwtToken);
                            if (loginForm.remember_me === true) {
                                localStorage.setItem("Token", jwtToken);

                            } else {
                                sessionStorage.setItem("Token", jwtToken);
                            }

                            const decodedToken = decodeJwtToken(jwtToken);

                            if (decodedToken) {
                                const loginType = decodedToken["login_type"];
                                if (loginType === 2) {
                                    // console.log("new user");
                                    navigate("/create-password");
                                }
                                else {
                                    navigate("/");
                                }
                            } else {
                                console.log('Failed to decode token.');
                            }
                        }
                    }
                }
            })
            .catch(function (error) {
                if (error.message !== undefined && error.message === "Network Error") {
                    alert("The server might be down, if this problem persists contact the developer")
                }
                if (error.response !== undefined && error.response.data !== undefined) {
                    if (error.response.data.error !== undefined) {
                        alert(error.response.data.error);
                    }
                    else {
                        alert(error);
                    }
                }
            });
    };


    const toggleVisibility = () => setIsVisible(!isVisible);

    const onChangeCheckBox = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setChecked(e.target.checked);
        handleLoginForm({ ...loginForm, remember_me: e.target.checked });
    };


    return (
        <ExternalLayout title="Welcome to WaliaApp!" subtitle="Login to your account" showTitle={true}>
            <form >
                <div className="p-2">

                    <Input
                        key={"outside"}
                        placeholder='Email or Username'
                        name="username"
                        onChange={onChange}
                        radius='sm'
                        endContent={
                            <MailIcon className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                        }
                    />
                </div>
                <div className="p-2">

                    <Input
                        name="password"
                        onChange={onChange}
                        placeholder="Password"
                        endContent={
                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                {isVisible ? (
                                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                ) : (
                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                )}
                            </button>
                        }
                        type={isVisible ? "text" : "password"}
                    />
                </div>
                <Spacer y={2} />
                <div className='p-2'>
                    <Button
                        onClick={(e) => handleSubmitClick(e)}
                        className={style['action-btn']}
                        fullWidth
                        radius="sm"
                    >
                        Sign In
                    </Button>
                </div>
                <div className='p-2'>
                    <Row justify={"space-between"}>
                        <Col>
                            <Checkbox
                                checked={checked}
                                onChange={onChangeCheckBox}
                            >
                                <p
                                    style={{ color: "#777777", marginBottom: 0 }}
                                >
                                    Remember Me
                                </p>

                            </Checkbox>
                        </Col>
                        <Col>
                            <NextLink
                                href={"reset-password"}
                            >
                                <p
                                    style={{ color: "#777777" }}
                                >
                                    Forgot your password?
                                </p>
                            </NextLink>
                        </Col>
                    </Row>
                </div>
            </form>
        </ExternalLayout>
    );
}

export default LoginPage;
