import axios from "axios";
import React, { useState, useEffect, ChangeEvent } from "react";

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Row, Col, Input, Image, Dropdown, Space, message, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, FileImageOutlined } from '@ant-design/icons';

import * as myConstant from "../../../GlobalVariable";
import style from "./ModifyEmployeeStructureModal.module.css";
import { getUserType } from "../../../jwtDecoder";
import { StructureDataType } from "../../../GlobalVariable";


function ModifyEmployeeStructureModal(
    { title,
        employeeId,
        button,
        structures
    }:
        {
            title: string,
            employeeId: string,
            button: JSX.Element,
            structures: StructureDataType[]
        }
) {


    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleOpen = () => onOpen();

    // const [structures, setStructures] = useState<MenuProps["items"]>([]);
    const [selectedStructure, setSelectedStructure] = useState<any>(null);

    const [structureForm, setStructureForm] = useState({
        structureId: "",
        name: "",
    });

    const handleMenuClick = (value: any) => {
        setSelectedStructure(value);
        setStructureForm({ structureId: value.id, name: value.name })
    };

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {

            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);


    // useEffect(() => {
    //     if (authenticated === true && userType !== undefined) {
    //         var apiUrl: string = myConstant.BASE_URL + "management-structure/get-one/self/"
    //         if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
    //             apiUrl = myConstant.BASE_URL + "management-structure/get-all/"
    //         }
    //         else if (userType === "Supervisor") {
    //             apiUrl = myConstant.BASE_URL + "management-structure/get-down-stream/";
    //         }
    //         axios.get(apiUrl,
    //             {
    //                 headers: {
    //                     Accept: "application/json",
    //                     "Content-Type": "application/json",
    //                     Authorization:
    //                         "Bearer " +
    //                         (sessionStorage.getItem("Token") !== null
    //                             ? sessionStorage.getItem("Token")
    //                             : localStorage.getItem("Token")),
    //                 },
    //                 withCredentials: true,
    //             }
    //         ).then(function (response) {
    //             if (response.status === 200) {
    //                 var listOfEmployee: MenuProps["items"] = [];
    //                 for (let index = 0; index < response.data.result.length; index++) {
    //                     const element = response.data.result[index];
    //                     const structure = {
    //                         label: <button onClick={(e) => { handleMenuClick(element) }}>
    //                             {element.name}
    //                             {/* <Avatar size={25} icon={<Image
    //                                 src={`data:image/png;base64,${element["profile photo"]}`}
    //                                 alt="Base64 Encoded Image"
    //                             />} /> */}

    //                         </button>
    //                         ,
    //                         key: element.id,
    //                     };
    //                     listOfEmployee.push(structure);
    //                 }
    //                 setStructures(listOfEmployee);
    //             }
    //         }).catch(function (error) {
    //             console.log("error", error);
    //             if (error.response.data.error != null) {
    //                 alert(error.response.data.error);
    //             }
    //             else {
    //                 alert(error);
    //             }
    //         });
    //     }
    // }, [userType, authenticated]);


    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(employeeForm);

        axios
            .post(
                myConstant.BASE_URL + "management-structure/modify-member/",
                {
                    "member": employeeId,
                    "action": "assign",
                    "structure_id": structureForm.structureId
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.status.includes("Successfully")) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
    }

    return (
        <div>
            <Row justify={"end"}>
                <Col>
                    <div className='p-2'>
                        {React.cloneElement(button, { onClick: () => handleOpen() })}
                    </div>
                </Col>
            </Row>

            <Modal size="xl" backdrop={"blur"} isOpen={isOpen} onClose={onClose} isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1" style={{
                                borderBottom: "2px red solid",
                                textAlign: "center"
                            }}>
                                Change Structure To - {title}
                            </ModalHeader>
                            <ModalBody>

                                <div className="p-2" >
                                    <Row align={"middle"} justify={"space-around"} >
                                        <Col span={8}>
                                            <p style={{ marginBottom: 0 }}>
                                                Structure
                                            </p>
                                        </Col>
                                        <Col span={14}>
                                            <Dropdown
                                                menu={{ items: structures }}
                                                trigger={["click"]}
                                                overlayStyle={{ maxHeight: 250, overflow: "scroll" }}
                                            >
                                                <div className={style["level-selector"]}>
                                                    <Row
                                                        className="pr-2"
                                                        style={{ height: "100%" }}
                                                        align={"middle"}
                                                        justify={"end"}
                                                    >
                                                        {(selectedStructure !== null) ?
                                                            <span> {selectedStructure["name"]}</span>
                                                            : "Select Structure"
                                                        }
                                                        <DownOutlined />
                                                    </Row>
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-center">
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button
                                    className="action-btn"
                                    onClick={(e) => handleSubmitClick(e)}
                                >
                                    Change Membership
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </div>
    )
}

export default ModifyEmployeeStructureModal