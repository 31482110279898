
import { Card, CardBody } from "@nextui-org/react";
import { Col, Row } from 'antd';
import { Container, Stack } from 'react-bootstrap';



import style from "./ExternalLayout.module.css";
// import external_img from "../assets/external-img.jpg";
import external_pic from "../assets/external-pic.png";

function ExternalLayout({ children, title, subtitle, showTitle }: { children: any, title: string, subtitle: string, showTitle: boolean }) {


    return (
        <Container className={style["layout"]} >
            <Container>
                <Row>
                    <Col span={2}></Col>
                    <Col
                        span={20}
                    >
                        <Card>
                            <CardBody>
                                <Row gutter={16} justify={"center"} align={"middle"}>
                                    <Col xs={24} sm={24} md={12}>
                                        <img
                                            src={external_pic}
                                            alt="Login"
                                            height={500}
                                        />
                                    </Col>
                                    <Col></Col>
                                    <Col xs={24} sm={24} md={9} >
                                        <Container >
                                            <Stack gap={0}>

                                                {
                                                    showTitle ?
                                                        <>
                                                            <div className="pt-4">
                                                                <p id={style["card-header"]}>
                                                                    {title}
                                                                </p>
                                                            </div>
                                                            <div className="p-1" style={{
                                                                borderBottom: "7px solid #FDC00D",
                                                                marginBottom: "2rem",
                                                                marginInline: "2rem"
                                                            }}>
                                                                <p id={style["page-title"]}>
                                                                    {subtitle}
                                                                </p>
                                                            </div>
                                                        </> :
                                                        <>
                                                        </>
                                                }


                                                {children}
                                            </Stack>
                                        </Container>

                                    </Col>
                                    <Col></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ExternalLayout