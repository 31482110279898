import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Space, Input, Tooltip } from 'antd';
import { Spacer, Button, } from "@nextui-org/react";

import { EditOutlined } from '@ant-design/icons';

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import DeleteModal from "../../../components/modals/DeleteModal/DeleteModal";
import InternalLayout from '../../../layout/InternalLayout';
import editIcon from "../../../assets/edit-icon.png";
import deleteIcon from "../../../assets/delete-icon.png";
import AddStructureModal from "../../../components/modals/AddStructureModal/AddStructureModal";
import * as myConstant from "../../../GlobalVariable";
import { StructureDataType, StructureLevelDataType } from "../../../GlobalVariable";
import AssignHeadModal from "../../../components/modals/AssignHeadModal/AssignHeadModal";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { getUserType } from "../../../jwtDecoder";

import type { InputRef } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';


type DataIndex = keyof StructureDataType;


function StructurePage() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {
            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    const [apiData, setApiData] = useState<{ data: StructureDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );
    const [apiStructureLevelData, setApiStructureLevelData] = useState<{ data: StructureLevelDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );
    const [employees, setEmployees] = useState<any>([]);


    useEffect(() => {
        if (authenticated === true && userType !== undefined && ["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) {
            setApiStructureLevelData({ ...apiStructureLevelData, loading: true });

            var apiUrl: string = myConstant.BASE_URL + "structure-level/get-org-structure/null/"
            if (["Owner", "SuperAdmin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "structure-level/get-all/"
            }

            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfStructure: StructureLevelDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const structure = {
                            key: index,
                            name: element.name,
                            id: element.id,
                            title: element.title,
                            parent: element.parent,
                            description: element.description,
                        }
                        listOfStructure.push(structure);
                    }
                    setApiStructureLevelData({ ...apiStructureLevelData, data: listOfStructure, loaded: true, loading: false });
                }
            }).catch(function (error) {
                // console.log(error);
                alert(error);
            });
        }
    }, [userType]);

    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            setApiData({ ...apiData, loading: true });

            var apiUrl: string = myConstant.BASE_URL + "management-structure/get-one/self/"
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "management-structure/get-all/"
            }
            else if (userType === "Supervisor") {
                apiUrl = myConstant.BASE_URL + "management-structure/get-down-stream/";
            }
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfStructure: StructureDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const structure = {
                            key: index + 1,
                            id: element.id,
                            isEditable: element.isEditable,
                            name: element.name,
                            title: element.title,
                            head: element.head,
                            parentStructure: element["parent structure"],
                            description: element.description,
                            childStructures: element["child structures"],
                            structureLevel: element["structure level"],
                        }
                        listOfStructure.push(structure);
                    }
                    setApiData({ ...apiData, data: listOfStructure, loaded: true, loading: false });
                }
            }).catch(function (error) {
                // console.log(error);
                alert(error.response.data.error);
            });
        }
    }, [userType]);


    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            var apiUrl: string = myConstant.BASE_URL + "account/get-down-stream/";
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "account/get-all/"
            }

            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfEmployee: any = [];
                    for (let index = 0; index < response.data.result.employees.length; index++) {
                        const element = response.data.result.employees[index];
                        listOfEmployee.push(element);
                    }
                    setEmployees(listOfEmployee);
                }
            }).catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
        }


    }, [userType]);


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void, dataIndex: string) => {
        clearFilters();
        setSearchText("");
        setSearchedColumn("");
        if (dataIndex === "name" && searchInput.current) {
            searchInput.current.focus();
        }
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<StructureDataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        color="primary"
                        variant="bordered"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        startContent={<SearchOutlined />}
                    >
                        Search
                    </Button>
                    <Button
                        color="warning"
                        variant="bordered"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                    >
                        Clear
                    </Button>
                    <Button
                        color="danger"
                        variant="bordered"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    // for table search
    const columns: ColumnsType<StructureDataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            sorter: (a, b) => {
                if (a.key < b.key) {
                    return 1;
                } else if (a.key > b.key) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Structure Level',
            dataIndex: 'structureLevel',
            key: 'structureLevel',
            ...getColumnSearchProps('structureLevel'),
            sorter: (a, b) => {
                if (a.structureLevel < b.structureLevel) {
                    return 1;
                } else if (a.structureLevel > b.structureLevel) {
                    return -1;
                } else {
                    return 0;
                }
            },
            render: (_, record) => (
                <>
                    <Row justify={"space-between"}>
                        <Col>
                            <span style={{ paddingLeft: "10px" }}>
                                {record.structureLevel}
                            </span>
                        </Col>
                        <Col>
                            {
                                record.isEditable ?
                                    // <AssignHeadModal
                                    //     title={record.name + " (" + record.structureLevel + ")"}
                                    //     structureId={record.id}
                                    //     button={<button
                                    //         key="blur"
                                    //         style={{ paddingInline: 10 }}
                                    //     >
                                    //         <EditOutlined />
                                    //     </button>} />
                                    <AddStructureModal
                                        structureLevel={apiStructureLevelData.data}
                                        structureNameOld={record.name}
                                        structureIdOld={record.id}
                                        descriptionOld={record.description}
                                        structureLevelOld={record.structureLevel}
                                        editStructureLevel={true}
                                        button={
                                            // <Button
                                            //     key="blur"
                                            //     variant="flat"
                                            //     // color="warning"
                                            //     // onPress={() => handleOpen(b)}
                                            //     className="capitalize action-btn"
                                            // >
                                            //     Add Structure
                                            // </Button>
                                            <Tooltip title="Edit Structure Level">
                                                <button>
                                                    <EditOutlined />
                                                    {/* <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        width={20}
                                                    /> */}
                                                </button>
                                            </Tooltip>

                                        }
                                    />
                                    : null
                            }
                        </Col>
                    </Row>


                </>
            ),
        },
        {
            title: 'Structure Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                if (a.name < b.name) {
                    return 1;
                } else if (a.name > b.name) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Head',
            dataIndex: 'head',
            key: 'head',
            ...getColumnSearchProps('head'),
            render: (_, record) => (
                <>
                    <Row justify={"space-between"}>
                        <Col>
                            <span style={{ paddingLeft: "10px" }}>
                                {record.head}
                            </span>
                        </Col>
                        <Col>
                            {
                                record.isEditable ?
                                    <AssignHeadModal
                                        title={record.name + " (" + record.structureLevel + ")"}
                                        structureId={record.id}
                                        employeesData={employees}
                                        button={<button
                                            key="blur"
                                            style={{ paddingInline: 10 }}
                                        >
                                            <EditOutlined />
                                        </button>} />
                                    : null
                            }
                        </Col>
                    </Row>


                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) =>
                (userType !== undefined && ["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) && record.isEditable ?
                    <Row align={"middle"} justify={"center"}>
                        <Col>
                            <AddStructureModal
                                structureLevel={apiStructureLevelData.data}
                                structureNameOld={record.name}
                                structureIdOld={record.id}
                                descriptionOld={record.description}
                                editMode={true}
                                button={
                                    // <Button
                                    //     key="blur"
                                    //     variant="flat"
                                    //     // color="warning"
                                    //     // onPress={() => handleOpen(b)}
                                    //     className="capitalize action-btn"
                                    // >
                                    //     Add Structure
                                    // </Button>
                                    <Tooltip title="Edit Structure Details - (Name and Description)">
                                        <button>
                                            <img
                                                src={editIcon}
                                                alt="edit"
                                                width={20}
                                            />
                                        </button>
                                    </Tooltip>

                                }
                            />

                        </Col>
                        <Spacer x={1}></Spacer>
                        <Col>
                            <DeleteModal
                                title={"Management Structure"}
                                incomingDataId={record.id}
                                incomingApiUrl={"management-structure/delete-one/"}
                                button={<button >
                                    <img
                                        src={deleteIcon}
                                        alt="delete-structure"
                                        width={20}
                                    />
                                </button>}
                            />
                        </Col>
                    </Row> : <></>,
        },
    ];


    return (
        <>
            <InternalLayout title='Structures'>
                <>
                    {
                        (userType !== undefined && ["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) ?
                            <AddStructureModal
                                structureLevel={apiStructureLevelData.data}
                                editMode={false}
                                editStructureLevel={false}
                                button={
                                    <Button
                                        key="blur"
                                        variant="flat"
                                        // color="warning"
                                        // onPress={() => handleOpen(b)}
                                        className="capitalize action-btn"
                                    >
                                        Add Structure
                                    </Button>
                                }
                            /> : <></>
                    }

                    <Spacer y={2} />
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => <Row>
                                <Col>
                                    <p><span style={{ fontWeight: 800 }}> Parent Structure: </span>{record.parentStructure}</p>
                                    <p><span style={{ fontWeight: 800 }}> Sub-Structures Count: </span>{record.childStructures}</p>
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 800 }}> Description: </span> {record.description}</p>
                                </Col>
                            </Row>,
                            rowExpandable: (record) => record.name !== 'Not Expandable',
                        }}
                        dataSource={apiData.data}
                        pagination={{ pageSize: 10 }}
                        bordered={true}
                    />
                </>
            </InternalLayout>
        </>
    )
}

export default StructurePage