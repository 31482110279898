import axios from "axios";
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Spacer, Input, Button, Link as NextLink } from "@nextui-org/react";
// import { Row, Col } from "antd";

import style from "./ResetPasswordPage.module.css";
import { MailIcon } from '../../components/Icons/MailIcon';
import check_img from "../../assets/check-img.png";
import ExternalLayout from '../../layout/ExternalLayout';
import * as myConstant from "../../GlobalVariable";

function ResetPasswordPage() {

    const [messageSent, setMessageSent] = useState(false);

    const navigate = useNavigate();


    const [resetForm, handleResetForm] = useState<{ email: string }>({
        email: "",
    });

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        handleResetForm({ ...resetForm, [e.target.name]: e.target.value });
    };

    const [passwordSaved, setPasswordSaved] = useState(false);


    const sendMessage = () => navigate("/login");

    const handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        axios
            .post(myConstant.BASE_URL + "account/self/password-reset/",
                {
                    "id": resetForm.email,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                // console.log(response);
                if (response.status === 200) {
                    // console.log("state", response.data);
                    if (response.data !== undefined && response.data.result !== undefined) {
                        const message = response.data.result;
                        setMessageSent(true);
                    }
                }
            })
            .catch(function (error) {
                if (error.message !== undefined && error.message === "Network Error") {
                    alert("The server might be down, if this problem persists contact the developer")
                }
                if (error.response !== undefined && error.response.data !== undefined) {
                    if (error.response.data.error !== undefined) {
                        alert(error.response.data.error);
                    }
                    else {
                        alert(error);
                    }
                }
            });
    };



    return (
        <ExternalLayout
            title='Forget Password'
            subtitle='Please Enter Your Email'
            showTitle={!messageSent}
        >
            {
                messageSent ?
                    <>
                        <div className="pt-4" style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={check_img}
                                alt="check"
                                width={70}
                            />
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className="pt-2">
                            <p id={style["message-header"]}>
                                Password Reset Email Sent!
                            </p>
                        </div>
                        <div className="pt-2">
                            <p id={style["message-subtitle"]}>
                                An email has been sent to your {resetForm.email}. Follow
                                the instruction n the email to reset our password.
                            </p>
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className='p-2'>
                            <Button
                                className={style['action-btn']}
                                fullWidth
                                radius="sm"
                                onClick={sendMessage}
                            >
                                Done
                            </Button>
                        </div>
                    </> :
                    <>
                        <div className="p-2">
                            <Input
                                key={"outside"}
                                type="email"
                                name="email"
                                // label="Email"
                                placeholder='Email'
                                // labelPlacement={"outside"}
                                radius='sm'
                                endContent={
                                    <MailIcon className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                                }
                                onChange={onChange}
                            // className="max-w-xs"

                            />
                        </div>
                        <Spacer y={5} />
                        <div className='p-2'>
                            <Button
                                className={style['action-btn']}
                                fullWidth
                                radius="sm"
                                style={{
                                    paddingBlock: "1.5rem",
                                    fontSize: "1.2rem",
                                }}
                                onClick={handleSubmitClick}
                            >
                                Submit
                            </Button>
                            <div className='p-2'>
                                <NextLink
                                    href={"login"}
                                >
                                    <p
                                        style={{ color: "#777777" }}
                                    >
                                        Login
                                    </p>
                                </NextLink>
                            </div>
                        </div>
                    </>
            }

        </ExternalLayout>
    )
}

export default ResetPasswordPage