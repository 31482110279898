export const StructureIcon = ({ color, ...props }: { color: string, props?: any }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 15.9998H6.66667V27.9998H4V15.9998ZM25.3333 10.6665H28V27.9998H25.3333V10.6665ZM14.6667 2.6665H17.3333V27.9998H14.6667V2.6665Z"
            fill={color}
        />
    </svg>

)