import axios from "axios";
import React, { useState } from "react";
import { Spacer, Input, Button, Link as NextLink } from "@nextui-org/react";
import { Col, Row, Checkbox, Divider } from 'antd';
import { useNavigate } from "react-router-dom";

import style from "./CreateNewPasswordPage.module.css"
import ExternalLayout from '../../layout/ExternalLayout'
import { EyeSlashFilledIcon } from '../../components/Icons/EyeSlashFilledIcon';
import { EyeFilledIcon } from '../../components/Icons/EyeFilledIcon';
import check_img from "../../assets/check-img.png";
import * as myConstant from "../../GlobalVariable";


function CreateNewPasswordPage() {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [passwordSaved, setPasswordSaved] = useState(false);

    const [updatePasswordForm, setUpdatePasswordForm] = useState({
        currentPass: "",
        newPass: "",
        confirmPass: "",
    });

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setUpdatePasswordForm({ ...updatePasswordForm, [e.target.name]: e.target.value });
    };

    const toggleVisibility = () => setIsVisible(!isVisible);

    const savePassword = () => navigate("/login");

    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(updatePasswordForm);

        axios
            .put(myConstant.BASE_URL +
                "account/edit-password/",
                {
                    "current-pass": updatePasswordForm.currentPass,
                    "new-pass": updatePasswordForm.newPass,
                    "confirm-pass": updatePasswordForm.confirmPass,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),

                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("Successfully")) {
                        // window.location.reload();
                        setPasswordSaved(true);
                        localStorage.clear();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });

    }
    return (
        <ExternalLayout
            title='Change Password'
            subtitle='Create New Password'
            showTitle={!passwordSaved}
        >
            {
                passwordSaved ? (
                    <>
                        <div className="pt-4" style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={check_img}
                                alt="check"
                                width={70}
                            />
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className="pt-2">
                            <p id={style["message-header"]}>
                                Password Changed!
                            </p>
                        </div>
                        <div className="pt-2">
                            <p id={style["message-subtitle"]}>
                                Your password has been successfully Changed
                            </p>
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className='p-2'>
                            <Button
                                className={style['action-btn']}
                                fullWidth
                                radius="sm"
                                onClick={savePassword}
                            >
                                Back to Login
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="p-2">
                            <Input
                                // label="Password"
                                // variant="bordered"
                                name="currentPass"
                                onChange={onChange}
                                placeholder="Current Password"
                                endContent={
                                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        )}
                                    </button>
                                }
                                type={isVisible ? "text" : "password"}
                            // className="max-w-xs"
                            />
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className="p-2">

                            <Input
                                // label="Password"
                                // variant="bordered"
                                name="newPass"
                                placeholder="Password"
                                onChange={onChange}
                                endContent={
                                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        )}
                                    </button>
                                }
                                type={isVisible ? "text" : "password"}
                            // className="max-w-xs"
                            />
                        </div>
                        <div className="p-2">

                            <Input
                                // label="Password"
                                // variant="bordered"
                                name="confirmPass"
                                placeholder="Password"
                                onChange={onChange}
                                endContent={
                                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        )}
                                    </button>
                                }
                                type={isVisible ? "text" : "password"}
                            // className="max-w-xs"
                            />
                        </div>
                        <Spacer y={4}></Spacer>
                        <div className='p-2'>
                            <Button
                                className={style['action-btn']}
                                fullWidth
                                radius="sm"
                                onClick={(e) => handleSubmitClick(e)}
                            >
                                Update Password
                            </Button>
                        </div>
                    </>
                )
            }

        </ExternalLayout>
    )
}

export default CreateNewPasswordPage