import axios from "axios";
import React, { useState, useEffect, ChangeEvent } from "react";
import { Spacer } from "@nextui-org/react";

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Row, Col, Input, Image, Dropdown, Space, message, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, FileImageOutlined } from '@ant-design/icons';

import * as myConstant from "../../../GlobalVariable";
import style from "./AssignHeadModal.module.css";

const { TextArea } = Input;

function DeleteModal({ title, incomingDataId, incomingApiUrl, button }: { title: string, incomingDataId: string, incomingApiUrl: string, button: JSX.Element }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleOpen = () => onOpen();

    const [apiPayload, setApiPayload] = useState({
        dataId: incomingDataId,
        apiUrl: incomingApiUrl,
        reason: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setApiPayload({ ...apiPayload, reason: e.target.value });
    };

    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        axios
            .delete(
                myConstant.BASE_URL + incomingApiUrl,
                {
                    data: {
                        "id": apiPayload.dataId,
                        "reason": apiPayload.reason,
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("successfully")) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
                else {
                    alert(error);
                }
            });
    }

    return (
        <div>
            <Row justify={"end"}>
                <Col>
                    <div className='p-2'>
                        {React.cloneElement(button, { onClick: () => handleOpen() })}
                    </div>
                </Col>
            </Row>
            <Modal size="xl" backdrop={"blur"} isOpen={isOpen} onClose={onClose} isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1" style={{
                                borderBottom: "2px red solid",
                                textAlign: "center",
                                fontWeight: "700"
                            }}>
                                Delete {title}
                            </ModalHeader>
                            <ModalBody>

                                <div className="p-2">
                                    <Row align={"middle"} justify={"start"}>
                                        <p
                                            style={{
                                                marginBottom: 0,
                                                fontSize: "1.2rem",
                                            }}
                                        >
                                            Deletion Remark
                                        </p>
                                    </Row>
                                    <Spacer y={2}></Spacer>
                                    <Row>
                                        <TextArea
                                            name="reason"
                                            showCount
                                            rows={4}
                                            placeholder="maxLength is 250"
                                            maxLength={250}
                                            onChange={onChange}
                                        />
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-center">
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button
                                    className="action-btn"
                                    onClick={(e) => handleSubmitClick(e)}
                                >
                                    Delete
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default DeleteModal;