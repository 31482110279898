import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Image, Avatar, Space, Input, Tooltip, notification } from 'antd';

import { Spacer, Button, Link } from "@nextui-org/react";

import editIcon from "../../assets/edit-icon.png";
import { EditOutlined } from '@ant-design/icons';
import deleteIcon from "../../assets/delete-icon.png";
import InternalLayout from '../../layout/InternalLayout'
import { EmployeeDataType, StructureDataType } from '../../GlobalVariable';
import AddEmployeeModal from '../../components/modals/AddEmployeeModal/AddEmployeeModal';
import * as myConstant from "../../GlobalVariable";
import DeleteModal from "../../components/modals/DeleteModal/DeleteModal";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';

import type { InputRef } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { getUserType } from "../../jwtDecoder";
import ModifyEmployeeStructureModal from "../../components/modals/ModifyEmployeeStructureModal/ModifyEmployeeStructureModal";

type DataIndex = keyof EmployeeDataType;
type NotificationType = 'success' | 'info' | 'warning' | 'error';



function EmployeePage() {

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        api[type]({
            message: title,
            description: message,
        });
    };

    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);

    const [apiData, setApiData] = useState<{ data: EmployeeDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );

    const [apiStructureData, setApiStructureData] = useState<{ data: StructureDataType[], loaded: boolean, loading: boolean }>(
        { data: [], loaded: false, loading: false, }
    );

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const [userType, setUserType] = useState<string | null>();
    useEffect(() => {
        if (authenticated === true) {

            if (localStorage.getItem("Token") !== null) {
                const incomingType = getUserType(localStorage.getItem("Token")!);
                setUserType(incomingType);
            }
            if (sessionStorage.getItem("Token") !== null) {
                const incomingType = getUserType(sessionStorage.getItem("Token")!);
                setUserType(incomingType);
            }
        }
    }, [authenticated]);

    useEffect(() => {
        const fetchManagementStructure = async () => {
            var apiUrl: string = myConstant.BASE_URL + "management-structure/get-one/self/"
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "management-structure/get-all/"
            }
            else if (userType === "Supervisor") {
                apiUrl = myConstant.BASE_URL + "management-structure/get-down-stream/";
            }
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                console.log({ "status": "requesting" });
                if (response.status === 200) {
                    console.log({ "status": "request successful" });
                    var listOfStructure: StructureDataType[] = [];
                    for (let index = 0; index < response.data.result.length; index++) {
                        const element = response.data.result[index];
                        const structure = {
                            key: index + 1,
                            isEditable: element.isEditable,
                            id: element.id,
                            name: element.name,
                            title: element.title,
                            head: element.head,
                            parentStructure: element["parent structure"],
                            description: element.description,
                            childStructures: element["child structures"],
                            structureLevel: element["structure level"],
                        }
                        listOfStructure.push(structure);
                    }
                    setApiStructureData({ ...apiStructureData, data: listOfStructure, loaded: true, loading: false });
                }
            }).catch(function (error) {
                // console.log(error);
                console.error("Error response:", error.response);
                console.error("Error details:", error.response?.data);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
            });

        }
        if (authenticated === true && userType !== undefined) {
            setApiStructureData({ ...apiStructureData, loading: true });
            fetchManagementStructure();
        }

    }, [userType, authenticated]);

    useEffect(() => {
        if (authenticated === true && userType !== undefined) {
            setApiData({ ...apiData, loading: true });

            var apiUrl = myConstant.BASE_URL + "account/get-one/self/";
            if (["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0) {
                apiUrl = myConstant.BASE_URL + "account/get-all/"
            }
            else if (userType === "Supervisor") {
                apiUrl = myConstant.BASE_URL + "account/get-down-stream/";
            }

            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    var listOfEmployee: EmployeeDataType[] = [];
                    for (let index = 0; index < response.data.result.employees.length; index++) {
                        const element = response.data.result.employees[index];
                        const employee = {
                            key: index + 1,
                            id: element.id,
                            isViewer: element["isViewer"],
                            email: element.email,
                            title: element.title,
                            prefix: element.prefix,
                            firstName: element["first name"],
                            lastName: element["last name"],
                            fullName: element["full name"],
                            phoneNumber: element["phone number"],
                            createdOn: element["created on"],
                            belongsTo: element["belongs to"],
                            profilePic: element["profile photo"],
                            status: element["status"],
                            username: element.username,
                            type: element.type,
                            editEmail: false,
                            editUsername: false,
                            otpSent: element["opt sent"],
                        }
                        // console.log(employee)
                        listOfEmployee.push(employee);
                    }
                    setApiData({ ...apiData, data: listOfEmployee, loaded: true, loading: false });
                }
            }).catch(function (error) {
                // console.log(error);
                if (error.response.data.error != null) {
                    alert(error.response.data.error);
                }
            });

        }
    }, [userType, authenticated]);


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void, dataIndex: string) => {
        clearFilters();
        setSearchText("");
        setSearchedColumn("");
        if (dataIndex === "name" && searchInput.current) {
            searchInput.current.focus();
        }
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<EmployeeDataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        color="primary"
                        variant="bordered"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        startContent={<SearchOutlined />}
                    >
                        Search
                    </Button>
                    <Button
                        color="warning"
                        variant="bordered"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                    >
                        Clear
                    </Button>
                    <Button
                        color="danger"
                        variant="bordered"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<EmployeeDataType> = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            sorter: (a, b) => {
                if (a.key < b.key) {
                    return 1;
                } else if (a.key > b.key) {
                    return -1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            ...getColumnSearchProps('fullName'),
            sorter: (a, b) => {
                if (a.fullName < b.fullName) {
                    return 1;
                } else if (a.fullName > b.fullName) {
                    return -1;
                } else {
                    return 0;
                }
            },
            render: (_, record) => (
                <>
                    <Avatar size={25} icon={<Image
                        src={`data:image/png;base64,${record.profilePic}`}
                        alt="Base64 Encoded Image"
                    />} />

                    <span style={{ paddingLeft: "10px" }}>
                        {record.fullName}
                    </span>
                </>
            ),
        },
        {
            title: 'Belongs To',
            dataIndex: '',
            key: 'belongsTo',
            ...getColumnSearchProps('belongsTo'),
            sorter: (a, b) => {
                if (a.belongsTo < b.belongsTo) {
                    return 1;
                } else if (a.belongsTo > b.belongsTo) {
                    return -1;
                } else {
                    return 0;
                }
            },
            render: (record) => (
                <Row>
                    <Col>
                        <p>
                            {
                                record.type !== "SuperAdmin" ?
                                    record.belongsTo : "System Admin"
                            }
                        </p>
                    </Col>
                    {(["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) && !record.isViewer && record.type !== "SuperAdmin" ?
                        <>
                            <Col>
                                <Spacer x={4}></Spacer>
                            </Col>
                            <Col>
                                <ModifyEmployeeStructureModal
                                    title={"test"}
                                    employeeId={record.id}
                                    structures={apiStructureData.data}
                                    button={
                                        <Tooltip title="Edit Users Structure">
                                            <button
                                                key="blur"
                                                style={{ paddingInline: 10 }}
                                            >
                                                <EditOutlined />
                                            </button>
                                        </Tooltip>
                                    } />
                                {/* <button>
                                        <EditOutlined />
                                    </button> */}
                            </Col>
                        </> : null
                    }

                </Row>
            )
        },
        // { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) =>
                (["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) && !record.isViewer ?
                    <Row align={"middle"} justify={"center"}>
                        <Col>
                            <AddEmployeeModal
                                incomingStructure={apiStructureData.data}
                                editMode={true}
                                userIdOld={record.id}
                                usernameOld={record.username}
                                prefixOld={record.prefix}
                                firstNameOld={record.firstName}
                                lastNameOld={record.lastName}
                                phoneNumberOld={record.phoneNumber}
                                button={
                                    <Tooltip title="Edit User's Account Details">
                                        <button>
                                            <img
                                                src={editIcon}
                                                alt="edit"
                                                width={20}
                                            />
                                        </button>
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Spacer x={1}></Spacer>
                        <Col>
                            <DeleteModal
                                title={"Employee Account"}
                                incomingDataId={record.id}
                                incomingApiUrl={"account/delete-one/"}
                                button={<button >
                                    <img
                                        src={deleteIcon}
                                        alt="delete-employee"
                                        width={20}
                                    />
                                </button>}
                            />
                        </Col>
                    </Row> : <></>,
        },
    ];

    function editAccountEmail(key: any) {
        const newData = [...apiData.data];
        newData[key].editEmail = !newData[key].editEmail;
        setApiData({ ...apiData, data: newData });
    }

    function editAccountUsername(key: any) {
        const newData = [...apiData.data];
        newData[key].editUsername = !newData[key].editUsername;
        setApiData({ ...apiData, data: newData });
    }

    const [newDetails, setNewDetails] = useState({ email: "", username: "" });
    const onFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewDetails({ ...newDetails, [e.target.name]: e.target.value });
    };
    const handleSubmitEmailEdit = (
        userId: string,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log("newEmail", newDetails)
        axios
            .put(myConstant.BASE_URL +
                "account/edit-email/",
                {
                    "id": userId,
                    "email": newDetails.email,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(async function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("Successfully")) {
                        // successMessage(response.data.result);
                        openNotificationWithIcon("success", "Username update Succeed", response.data.result);
                        await timeout(1000);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                // errorMessage(error);
                openNotificationWithIcon("error", "Username update failed", error);
            });
    }

    const handleSubmitUsernameEdit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log("newEmail", newDetails)
        axios
            .put(myConstant.BASE_URL +
                "account/edit-username/",
                {
                    "email": newDetails.username,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(async function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("Successfully")) {
                        // successMessage(response.data.result);
                        openNotificationWithIcon("success", "Username update Succeed", response.data.result);
                        await timeout(1000);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                // errorMessage(error);
                openNotificationWithIcon("error", "Username update failed", error);
            });
    }

    const handleSubmitPasswordReset = (
        email: string,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        axios
            .post(myConstant.BASE_URL +
                "account/other/password-reset/",
                {
                    "id": email,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(async function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("Successfully")) {
                        // successMessage(response.data.result);
                        openNotificationWithIcon("success", "Username update Succeed", response.data.result);
                        await timeout(1000);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                // errorMessage(error);
                openNotificationWithIcon("error", "Username update failed", error);
            });
    }

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }
    return (
        <InternalLayout title='Employees'>
            <>
                {contextHolder}
                {["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0 ?
                    <AddEmployeeModal
                        incomingStructure={apiStructureData.data}
                        editMode={false}
                        button={
                            <Button
                                key="blur"
                                variant="flat"
                                className="capitalize action-btn"
                            >
                                Add Employee
                            </Button>
                        }
                    /> : null
                }

                <Spacer y={2} />
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record, index) => <Row>
                            <Col span={24}>
                                <p><span style={{ fontWeight: 800, paddingRight: "10px" }}> Title: </span>{record.title}</p>
                                <Row>
                                    <Col>
                                        <p>
                                            <span style={{ fontWeight: 800, paddingRight: "10px" }}>
                                                Email Address: </span>{record.email}
                                        </p>
                                    </Col>
                                    {
                                        (["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) && !record.isViewer && !record.editEmail ?
                                            <>
                                                <Col><Spacer x={3} /></Col>
                                                <Col>

                                                    <Tooltip title="Edit Users Email Addresses">
                                                        <button
                                                            onClick={() => editAccountEmail(index)}
                                                        >
                                                            <EditOutlined />
                                                        </button>
                                                    </Tooltip>
                                                </Col>
                                            </> : null
                                    }

                                </Row>
                                {
                                    record.editEmail ?
                                        <>
                                            <Row align={"middle"} style={{ marginBottom: "15px" }}>
                                                <Col span={3}> New Email:</Col>
                                                <Col span={8}>
                                                    <Input
                                                        name="email"
                                                        ref={searchInput}
                                                        // value={record.email}
                                                        style={{ display: 'block' }}
                                                        aria-label="edit-email"
                                                        onChange={onFormChange}
                                                    />
                                                </Col>
                                                <Col> <Spacer x={2} /> </Col>
                                                <Col>
                                                    <Button
                                                        key="blur"
                                                        variant="flat"
                                                        color="success"
                                                        onClick={(e) => handleSubmitEmailEdit(
                                                            record.id,
                                                            e
                                                        )}
                                                    >
                                                        Update
                                                    </Button>
                                                </Col>
                                                <Col> <Spacer x={2} /> </Col>
                                                <Col>
                                                    <Button
                                                        key="blur"
                                                        variant="flat"
                                                        color="danger"
                                                        onClick={() => editAccountEmail(index)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }
                                {
                                    record.isViewer ?
                                        <Row>
                                            <Col>
                                                <p>
                                                    <span style={{ fontWeight: 800, paddingRight: "10px" }}>
                                                        Username: </span>{record.username}
                                                </p>

                                            </Col>
                                            {
                                                (["Owner", "SuperAdmin",].indexOf(userType!) >= 0) || record.isViewer ?
                                                    <>
                                                        <Col><Spacer x={3} /></Col>
                                                        <Col>

                                                            <Tooltip title="Edit Users Email Addresses">
                                                                <button
                                                                    onClick={() => editAccountUsername(index)}
                                                                >
                                                                    <EditOutlined />
                                                                </button>
                                                            </Tooltip>
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                        </Row>

                                        : null
                                }

                                {
                                    record.editUsername ?
                                        <>
                                            <Row align={"middle"} style={{ marginBottom: "15px" }}>
                                                <Col span={3}> New Username:</Col>

                                                <Col span={8}>
                                                    <Input
                                                        name="username"
                                                        ref={searchInput}
                                                        // value={record.email}
                                                        style={{ display: 'block' }}
                                                        aria-label="edit-username"
                                                        onChange={onFormChange}
                                                    />
                                                </Col>
                                                <Col> <Spacer x={2} /> </Col>
                                                <Col>
                                                    <Button
                                                        key="blur"
                                                        variant="flat"
                                                        color="success"
                                                        onClick={(e) => handleSubmitUsernameEdit(e)}
                                                    >
                                                        Update
                                                    </Button>
                                                </Col>
                                                <Col> <Spacer x={2} /> </Col>
                                                <Col>
                                                    <Button
                                                        key="blur"
                                                        variant="flat"
                                                        color="danger"
                                                        onClick={() => editAccountUsername(index)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }

                                <p><span style={{ fontWeight: 800, paddingRight: "10px" }}> Phone Number: </span>{record.phoneNumber}</p>
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 800, paddingRight: "10px" }}> Account Created On : </span> {record.createdOn}</p>
                                <Spacer y={3}></Spacer>
                                {
                                    record.isViewer ?
                                        <>
                                            <Button
                                                variant="bordered"
                                            >
                                                <Link href="/create-password">
                                                    Change Password
                                                </Link>
                                            </Button>
                                        </> :
                                        (["Owner", "SuperAdmin", "Admin", "Supervisor"].indexOf(userType!) >= 0) ?
                                            <>
                                                <Row align={"middle"}>
                                                    {record.otpSent ? <>
                                                    </> : <>
                                                        <Col>
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                    marginRight: "6px",
                                                                }}
                                                            >
                                                                Error:
                                                            </span>
                                                            OTP hasn't been sent!

                                                        </Col>
                                                        <Col> <Spacer x={3} /></Col>
                                                    </>
                                                    }
                                                    <Col>
                                                        <Button
                                                            variant="bordered"
                                                            onClick={(e) => handleSubmitPasswordReset(record.email, e)}
                                                        >
                                                            Reset password
                                                        </Button>
                                                    </Col>
                                                </Row>


                                            </> : null
                                }
                            </Col>
                        </Row>,
                        rowExpandable: (record) => record.fullName !== 'Not Expandable',
                    }}
                    dataSource={apiData.data}
                    pagination={{ pageSize: 10 }}
                    bordered={true}
                />
            </>
        </InternalLayout >
    )
}

export default EmployeePage