import React from "react";
export const UnopenedTasksIcon = ({ ...props }: { props?: any }) => (
    <svg
        width="36"
        height="36"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22.5"
            cy="22.5"
            r="22.5"
            fill="#0C56E7"
            fillOpacity="0.2" />
        <path
            d="M21.997 32.385C21.5517 32.385 21.1717 32.2267 20.857 31.91C20.5423 31.594 20.385 31.214 20.385 30.77H23.615C23.615 31.2167 23.4567 31.5973 23.14 31.912C22.8227 32.2273 22.4417 32.385 21.997 32.385ZM15 29.769V28.769H16.615V20.846C16.615 19.5393 17.0283 18.3903 17.855 17.399C18.683 16.4077 19.7313 15.79 21 15.546V14H23V15.075C22.8553 15.3003 22.7287 15.528 22.62 15.758C22.5107 15.9873 22.4257 16.2313 22.365 16.49L22.187 16.47C22.129 16.4647 22.0667 16.462 22 16.462C20.7847 16.462 19.75 16.8887 18.896 17.742C18.0427 18.596 17.616 19.6307 17.616 20.846V28.769H26.385V22.69C26.5437 22.7213 26.707 22.7457 26.875 22.763C27.0443 22.7797 27.2143 22.7817 27.385 22.769V28.769H29V29.769H15ZM26.964 20.404C26.2707 20.404 25.68 20.161 25.192 19.675C24.7053 19.1897 24.462 18.6 24.462 17.906C24.462 17.2127 24.7047 16.6223 25.19 16.135C25.676 15.6477 26.266 15.404 26.96 15.404C27.6533 15.404 28.2433 15.6467 28.73 16.132C29.2167 16.6173 29.4607 17.2073 29.462 17.902C29.462 18.5953 29.219 19.1857 28.733 19.673C28.2477 20.1597 27.6577 20.403 26.963 20.403"
            fill="#0C56E7" />
    </svg>


)