import React from "react";

export const CanceledTasksIcon = ({ ...props }: { props?: any }) => (
    <svg
        width="35"
        height="35"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22.5"
            cy="22.5"
            r="22.5"
            fill="#D34C4C"
        />
        <path
            d="M30.8075 29.75L33.5 27.0575L32.4425 26L29.75 28.6925L27.0575 26L26 27.0575L28.6925 29.75L26 32.4425L27.0575 33.5L29.75 30.8075L32.4425 33.5L33.5 32.4425L30.8075 29.75Z"
            fill="white"
        />
        <path
            d="M29.75 14.75H27.5V14C27.4988 13.6025 27.3404 13.2217 27.0593 12.9407C26.7783 12.6596 26.3975 12.5012 26 12.5H20C19.6025 12.5012 19.2217 12.6596 18.9407 12.9407C18.6596 13.2217 18.5012 13.6025 18.5 14V14.75H16.25C15.8525 14.7512 15.4717 14.9096 15.1907 15.1907C14.9096 15.4717 14.7512 15.8525 14.75 16.25V32C14.7512 32.3975 14.9096 32.7783 15.1907 33.0593C15.4717 33.3404 15.8525 33.4988 16.25 33.5H23V32H16.25V16.25H18.5V18.5H27.5V16.25H29.75V23.75H31.25V16.25C31.2488 15.8525 31.0904 15.4717 30.8093 15.1907C30.5283 14.9096 30.1475 14.7512 29.75 14.75ZM26 17H20V14H26V17Z"
            fill="white"
        />
    </svg>
);
