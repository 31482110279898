import React from "react";
export const ReportIcon = ({ color, ...props }: { color: string, props?: any }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >

<path d="M14 8.78L18.24 1.45L19.97 2.45L14.74 11.5L8.23 7.75L3.46 16H20V18H0V0H2V14.54L7.5 5L14 8.78Z" 
fill={color}
/>


</svg>


);
