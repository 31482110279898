import axios from "axios";
import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Card, Col, Row, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

import InternalLayout from '../../layout/InternalLayout'
import { AllTasksIcon } from '../../components/Icons/AllTasksIcon';
import { Spacer } from '@nextui-org/react';

import styles from "./DashboardPage.module.css";
import * as myConstant from "../../GlobalVariable";

import { InProgressTasksIcon } from '../../components/Icons/InProgressTasksIcon';
import { CompletedTasksIcon } from '../../components/Icons/CompletedTasksIcons';
import { CanceledTasksIcon } from '../../components/Icons/CanceledTasksIcon';
import { UnopenedTasksIcon } from "../../components/Icons/UnopenedIcon";
import { PendingTaskIcon } from "../../components/Icons/PendingTasksIcon";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


function DashboardPage() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        }
    }, []);

    const [selectedMonth, setSelectedMonth] = useState("Annual");
    const [apiData, setApiData] = useState<{ loaded: boolean, loading: boolean }>(
        { loaded: false, loading: false, }
    );
    const [summaryData, setSummaryData] = useState({
        UnopenedTaskCount: 0,
        allTasksCount: 0,
        canceledTaskCount: 0,
        completedTaskCount: 0,
        inProgressTaskCount: 0,
        pendingTaskCount: 0
    });
    const [overviewData, setOverviewData] = useState({
        overviewLabel: [],
        total: [] as number[],
        completed: [] as number[],
        uncompleted: [] as number[],
        canceled: [] as number[],
    });

    const items: MenuProps['items'] = [
        {
            label: <a
                onClick={() => setSelectedMonth("Annual")} href='/#'>Annual </a>,
            key: '12',
        },
        {
            type: 'divider',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("January")}
                href='/#'
            >January </a>,
            key: '0',
        },
        {
            label:
                <a
                    onClick={() => setSelectedMonth("February")}
                    href='/#'
                > February </a>,

            key: '1',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("March")}
                href='/#'
            > March </a>,
            key: '2',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("April")}
                href='/#'
            > April </a>,
            key: '3',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("May")}
                href='/#'
            > May </a>,
            key: '4',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("June")}
                href='/#'
            > June </a>,
            key: '5',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("July")}
                href='/#'
            > July </a>,
            key: '6',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("August")}
                href='/#'
            > August </a>,
            key: '7',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("September")}
                href='/#'
            > September </a>,
            key: '8',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("October")}
                href='/#'
            > October </a>,
            key: '9',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("November")}
                href='/#'
            > November </a>,
            key: '10',
        },
        {
            label: <a
                onClick={() => setSelectedMonth("December")}
                href='/#'
            > December </a>,
            key: '11',
        },
    ];


    useEffect(() => {
        if (authenticated === true) {
            setApiData({ ...apiData, loading: true });
            const apiUrl = myConstant.BASE_URL + "summary/web-dashboard/false/placeholder/";
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    setApiData({ ...apiData, loaded: true, loading: false });
                    const summary = response.data.result.summary;
                    // console.log("summary", response.data)
                    setSummaryData({
                        UnopenedTaskCount: summary.UnopenedTaskCount,
                        allTasksCount: summary.allTasksCount,
                        canceledTaskCount: summary.canceledTaskCount,
                        completedTaskCount: summary.completedTaskCount,
                        inProgressTaskCount: summary.inProgressTaskCount,
                        pendingTaskCount: summary.pendingTaskCount,
                    })
                }
            }).catch(function (error) {
                // console.log(error);
                alert(error.response.data.error);
            });
        }
    }, [authenticated]);

    useEffect(() => {
        if (authenticated === true) {
            setApiData({ ...apiData, loading: true });
            const trailUrl = selectedMonth === "Annual" ? "summary/web-dashboard/false/placeholder/" : "summary/web-dashboard/true/" + selectedMonth + "/"
            const apiUrl = myConstant.BASE_URL + trailUrl;
            axios.get(apiUrl,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    setApiData({ ...apiData, loaded: true, loading: false });
                    const overviewResponse = response.data.result.overview;
                    // console.log("fd", overviewResponse)

                    if (selectedMonth === "Annual") {
                        // Define the correct order of months
                        const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                        let totalList = [] as number[];
                        let completedList = [] as number[];
                        let uncompletedList = [] as number[];
                        let canceledList = [] as number[];
                        for (let index = 0; index < monthOrder.length; index++) {
                            const element = monthOrder[index];
                            totalList.push(overviewResponse[element]["Total"]);
                            completedList.push(overviewResponse[element]["Completed"]);
                            uncompletedList.push(overviewResponse[element]["Uncompleted"]);
                            canceledList.push(overviewResponse[element]["Canceled"]);
                        }

                        setOverviewData({
                            overviewLabel: response.data.result.labels,
                            total: totalList,
                            completed: completedList,
                            uncompleted: uncompletedList,
                            canceled: canceledList,
                        })
                    }
                    else {
                        // Convert the nested object into lists
                        const weeks = Object.keys(overviewResponse);
                        const totalList = weeks.map((week) => overviewResponse[week].Total);
                        const completedList = weeks.map((week) => overviewResponse[week].Completed);
                        const uncompletedList = weeks.map((week) => overviewResponse[week].Uncompleted);
                        const canceledList = weeks.map((week) => overviewResponse[week].Canceled);

                        // Update state with the lists
                        setOverviewData({
                            overviewLabel: response.data.result.labels,
                            total: totalList,
                            completed: completedList,
                            uncompleted: uncompletedList,
                            canceled: canceledList,
                        });
                    }
                }
            }).catch(function (error) {
                // console.log(error);
                alert(error.response.data.error);
            });
        }
    }, [selectedMonth, authenticated])


    const data = {
        labels: overviewData.overviewLabel,
        datasets: [
            {
                label: 'Total',
                data: overviewData.total,
                backgroundColor: 'rgba(22, 26, 97, 1)',
            },
            {
                label: 'Completed',
                data: overviewData.completed,
                backgroundColor: 'rgba(20, 134, 102, 1)',
            },
            {
                label: 'Uncompleted',
                data: overviewData.uncompleted,
                backgroundColor: 'rgba(247, 148, 29, 1)',
            },
            {
                label: 'Canceled',
                data: overviewData.canceled,
                backgroundColor: 'rgba(211, 76, 76, 1)',
            },
        ],
    };

    const doughnutData = {
        labels: ['Canceled', 'Completed', 'UnCompleted'],
        datasets: [
            {
                data: [summaryData.canceledTaskCount, summaryData.completedTaskCount, summaryData.inProgressTaskCount + summaryData.pendingTaskCount + summaryData.UnopenedTaskCount],
                backgroundColor: [
                    'rgba(211, 76, 76, 1)',
                    'rgba(20, 134, 102, 1)',
                    'rgba(247, 148, 29, 1)',
                ],
                borderColor: [
                    'rgba(211, 76, 76, 1)',
                    'rgba(20, 134, 102, 1)',
                    'rgba(247, 148, 29, 1)',
                ],
                borderWidth: 1,
                cutout: "90%"
            },

        ],
    };


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: true,
                text: 'Overview',
            },
        },
    };
    const doughnutOptions = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'bottom' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Summary',
            },
        },
    };

    return (
        <InternalLayout title='Dashboard'>
            <Row justify="space-between">
                {/* all tasks */}
                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <AllTasksIcon width={35} height={35} />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>All Tasks</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.allTasksCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* Viewed tasks */}
                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <UnopenedTasksIcon />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>Unopened Tasks</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.UnopenedTaskCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <PendingTaskIcon />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>Pending Tasks</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.pendingTaskCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* in progress */}
                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <InProgressTasksIcon />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>In Progress</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.inProgressTaskCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* completed */}
                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <CompletedTasksIcon />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>Completed</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.completedTaskCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* canceled */}
                <Col>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col className={styles["summary-card-icon"]}>
                                <CanceledTasksIcon />
                            </Col>
                            <Col>
                                <Row>
                                    <span className={styles["summary-card-title"]}>Canceled</span>
                                </Row>
                                <Row>
                                    <span className={styles["summary-card-value"]}>{summaryData.canceledTaskCount} Tasks</span>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Spacer y={10} />
            <Row>
                <Col span={17}>
                    <Card style={{ width: "100%" }}>
                        <Row>
                            <Col>
                                <h5>Overview</h5>
                            </Col>
                            <Spacer x={3} />
                            <Col>
                                <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ maxHeight: 250, overflow: "scroll" }}>
                                    <a
                                        onClick={(e) => e.preventDefault()}
                                        href='/#'
                                    >
                                        <Space>
                                            {selectedMonth}
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Bar options={options} data={data} />
                    </Card>

                </Col>
                <Col span={1} ></Col>
                <Col span={6} >
                    <Card style={{ width: "100%" }}>
                        <div>
                            <Doughnut options={doughnutOptions} data={doughnutData} />
                        </div>
                        <Spacer y={10} />
                        <Row>
                            <div className={styles["dought-legend-complete"]}></div>
                            <p> Completed Tasks</p>
                        </Row>
                        <Row>
                            <div className={styles["dought-legend-canceled"]}></div>
                            <p> Canceled Tasks</p>
                        </Row>
                        <Row>
                            <div className={styles["dought-legend-in-progress"]}></div>
                            <p> Uncompleted Tasks</p>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </InternalLayout>
    )
}

export default DashboardPage