import React from "react";

export const InProgressTasksIcon = ({ ...props }: { props?: any }) => (
    <svg
        width="35"
        height="35"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22.5"
            cy="22.5"
            r="22.5"
            fill="#F7941D"
            fillOpacity="0.2"
        />
        <path
            d="M23 12.5C20.9233 12.5 18.8932 13.1158 17.1665 14.2696C15.4398 15.4233 14.094 17.0632 13.2993 18.9818C12.5045 20.9004 12.2966 23.0116 12.7018 25.0484C13.1069 27.0852 14.1069 28.9562 15.5754 30.4246C17.0438 31.8931 18.9148 32.8931 20.9516 33.2982C22.9884 33.7034 25.0996 33.4955 27.0182 32.7007C28.9368 31.906 30.5767 30.5602 31.7304 28.8335C32.8842 27.1068 33.5 25.0767 33.5 23C33.4968 20.2162 32.3896 17.5473 30.4211 15.5789C28.4527 13.6104 25.7838 12.5032 23 12.5ZM23 32C20.6131 32 18.3239 31.0518 16.636 29.364C14.9482 27.6761 14 25.3869 14 23C14 20.6131 14.9482 18.3239 16.636 16.636C18.3239 14.9482 20.6131 14 23 14V23L29.3608 29.3608C28.5266 30.1979 27.5352 30.862 26.4437 31.3149C25.3521 31.7679 24.1818 32.0007 23 32Z"
            fill="#FFC700"
        />
    </svg>

);
