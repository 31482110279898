import React from "react";
export const DashboardIcon = ({ color, ...props }: { color: string, props?: any }) => (
    // <svg
    //     aria-hidden="true"
    //     fill="none"
    //     focusable="false"
    //     height="1em"
    //     role="presentation"
    //     viewBox="0 0 24 24"
    //     width="1em"
    //     {...props}
    // >
    //     <path
    //         d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
    //         fill="currentColor"
    //     />
    //     <path
    //         d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
    //         fill="currentColor"
    //     />


    // </svg>

    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.6704 5.8457L12.0416 11.3656L12.2258 14.14C12.2278 14.4254 12.2725 14.7088 12.3588 14.9812C12.5813 15.51 13.1168 15.846 13.6993 15.8226L22.5756 15.2419C22.96 15.2356 23.3312 15.3794 23.6074 15.6416C23.8377 15.8602 23.9864 16.146 24.0333 16.4535L24.049 16.6402C23.6817 21.7264 19.9461 25.9688 14.8704 27.0639C9.79457 28.159 4.58965 25.8456 2.08145 21.3798C1.35836 20.0823 0.906706 18.6563 0.753013 17.1852C0.688813 16.7498 0.660546 16.3099 0.66848 15.8699C0.660546 10.4169 4.5438 5.70253 9.97961 4.56601C10.6339 4.46413 11.2752 4.81048 11.5376 5.40729C11.6054 5.5455 11.6502 5.69353 11.6704 5.8457Z"
            fill={color}
        />
        <g
            style={{ mixBlendMode: "lighten" }}
            opacity="0.4"
        >
            <path
                d="M27.3335 11.0829L27.3242 11.1263L27.2972 11.1895L27.301 11.363C27.2871 11.5928 27.1983 11.8138 27.0455 11.9925C26.8862 12.1785 26.6686 12.3052 26.429 12.3544L26.2828 12.3744L16.0418 13.038C15.7011 13.0716 15.3619 12.9617 15.1087 12.7358C14.8975 12.5474 14.7626 12.2933 14.7244 12.0194L14.0371 1.79325C14.0251 1.75868 14.0251 1.7212 14.0371 1.68661C14.0464 1.40473 14.1706 1.13829 14.3816 0.94681C14.5926 0.75533 14.8731 0.65477 15.1602 0.667596C21.24 0.822276 26.3499 5.19422 27.3335 11.0829Z"
                fill="#F7941D"
            />
        </g>
    </svg>

);
