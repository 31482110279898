import React from "react";
export const PendingTaskIcon = ({ ...props }: { props?: any }) => (
    <svg
        width="37"
        height="37"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="22.5" cy="22.5" r="22.5" fill="#444EFF" fillOpacity="0.2" />
        <path d="M27 23C24.24 23 22 25.24 22 28C22 30.76 24.24 33 27 33C29.76 33 32 30.76 32 28C32 25.24 29.76 23 27 23ZM28.65 30.35L26.5 28.2V25H27.5V27.79L29.35 29.64L28.65 30.35ZM28 14H24.82C24.4 12.84 23.3 12 22 12C20.7 12 19.6 12.84 19.18 14H16C14.9 14 14 14.9 14 16V31C14 32.1 14.9 33 16 33H22.11C21.5184 32.4258 21.0371 31.7479 20.69 31H16V16H18V19H26V16H28V21.08C28.71 21.18 29.38 21.39 30 21.68V16C30 14.9 29.1 14 28 14ZM22 16C21.45 16 21 15.55 21 15C21 14.45 21.45 14 22 14C22.55 14 23 14.45 23 15C23 15.55 22.55 16 22 16Z" fill="#444EFF" />
    </svg>



)