import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
} from "@nextui-org/react";
import { Row, Col, Input, message, Dropdown } from "antd";
import type { MenuProps } from "antd";

import { DownOutlined } from "@ant-design/icons";
import * as myConstant from "../../../GlobalVariable";

import style from "./AddStructureLevelModal.module.css";
import { StructureLevelDataType } from "../../../GlobalVariable";


const { TextArea } = Input;

function AddStructureLevelModal({
    structureLevel,
    structureLevelOldId,
    button,
    structureNameOld,
    parentOld,
    headTitleOld,
    descriptionOld,
    editMode,
}: {
    structureLevel: StructureLevelDataType[];
    button: JSX.Element;
    structureNameOld?: string | null;
    structureLevelOldId?: string | null;
    parentOld?: string | null;
    headTitleOld?: string | null;
    descriptionOld?: string | null;
    editMode?: boolean | null;
}) {
    const [messageApi, contextHolder] = message.useMessage();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [structureForm, setStructureForm] = useState({
        parentId: null,
        structureName: structureNameOld,
        headTitle: headTitleOld,
        description: descriptionOld,
    });

    const handleOpen = () => onOpen();

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setStructureForm({ ...structureForm, [e.target.name]: e.target.value });
    };
    const handleSubmitClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(structureForm);

        axios
            .post(myConstant.BASE_URL +
                "structure-level/add-new/",
                {
                    "parent-id": structureForm.parentId,
                    name: structureForm.structureName,
                    title: structureForm.headTitle,
                    description: structureForm.description,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.result.includes("successfully")) {
                        successMessage(response.data.result);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
                errorMessage(error.response.statusText);
            });
    };

    const handleSubmitEdit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        // console.log(structureForm);

        axios
            .put(myConstant.BASE_URL +
                "structure-level/edit-one/",
                {
                    "id": structureLevelOldId,
                    "parent-id": structureForm.parentId,
                    name: structureForm.structureName,
                    title: structureForm.headTitle,
                    description: structureForm.description,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.status.includes("successfully")) {
                        successMessage(response.data.result);
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error.response.statusText);
                errorMessage(error.response.statusText);
            });
    };


    const [parentStructure, setParentStructure] = useState<MenuProps["items"]>(
        []
    );
    useEffect(() => {
        var items: MenuProps["items"] = [];
        for (let index = 0; index < structureLevel.length; index++) {
            const element = structureLevel[index];
            const data = {
                label: <button onClick={(e) => { handleMenuClick(element) }}> {element.name}</button>
                ,
                key: element.id,
            };
            items.push(data);
        }
        setParentStructure(items);
    }, [structureLevel]);


    const [selectedItem, setSelectedItem] = useState<any>(null);

    // for edit model only
    useEffect(() => {
        if (editMode === true) {
            for (let index = 0; index < structureLevel.length; index++) {
                const element = structureLevel[index];
                if (element.name === parentOld) {
                    handleMenuClick(element);
                }
            }
        }
    }, [parentOld, structureLevel]);


    const handleMenuClick = (value: any) => {
        // e.key will contain the key of the clicked menu item
        // console.log(value);
        setSelectedItem(value);
        setStructureForm({ ...structureForm, parentId: value.id })
    };


    const successMessage = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div>
            <Row justify={"end"}>
                <Col>
                    <div className="p-2">
                        {React.cloneElement(button, { onClick: () => handleOpen() })}
                    </div>
                </Col>
            </Row>

            <Modal
                backdrop={"blur"}
                isOpen={isOpen}
                onClose={onClose}
                isDismissable={false}
                style={{ border: "1px #F7941D solid" }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader
                                className="flex flex-col gap-1"
                                style={{
                                    borderBottom: "1px #F7941D solid",
                                    textAlign: "center",
                                }}
                            >
                                {
                                    editMode === true ?
                                        "Edit Structure Details" :
                                        "Register Structure Level"
                                }
                            </ModalHeader>
                            <ModalBody>
                                <div className="p-2">
                                    <Row align={"middle"} justify={"space-around"}>
                                        <Col span={8}>
                                            <p style={{ marginBottom: 0 }}>Parent Structure Level</p>
                                        </Col>
                                        <Col span={14}>
                                            <Dropdown
                                                menu={{ items: parentStructure }}
                                                trigger={["click"]}
                                                overlayStyle={{ maxHeight: 250, overflow: "scroll" }}
                                            >
                                                <div className={style["level-selector"]}>
                                                    <Row
                                                        className="pr-2"
                                                        style={{ height: "100%" }}
                                                        align={"middle"}
                                                        justify={"end"}
                                                    >
                                                        {(selectedItem !== null) ?
                                                            selectedItem.name
                                                            : "Select Parent Structure"
                                                        }
                                                        <DownOutlined />
                                                    </Row>
                                                </div>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-2">
                                    <Row align={"middle"} justify={"space-around"}>
                                        <Col span={8}>
                                            <p style={{ marginBottom: 0 }}>Structure Level Name</p>
                                        </Col>
                                        <Col span={14}>
                                            <Input
                                                name="structureName"
                                                showCount
                                                maxLength={200}
                                                onChange={onChange}
                                                value={structureForm.structureName!}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="p-2">
                                    <Row align={"middle"} justify={"space-around"}>
                                        <Col span={8}>
                                            <p style={{ marginBottom: 0 }}>Structure Head Title</p>
                                        </Col>
                                        <Col span={14}>
                                            <Input
                                                name="headTitle"
                                                showCount
                                                maxLength={200}
                                                onChange={onChange}
                                                value={structureForm.headTitle!}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-2">
                                    <Row justify={"space-around"}>
                                        <Col span={8}>Description</Col>
                                        <Col span={14}>
                                            <TextArea
                                                name="description"
                                                placeholder="Description"
                                                allowClear
                                                onChange={onChange}
                                                value={structureForm.description!}
                                                style={{ height: 120, resize: "none" }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-center">
                                {contextHolder}
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                {
                                    editMode === true ?
                                        <>
                                            <Button
                                                className="action-btn"
                                                onClick={(e) => handleSubmitEdit(e)}
                                            >
                                                Update
                                            </Button>
                                        </> : <>
                                            <Button
                                                className="action-btn"
                                                onClick={(e) => handleSubmitClick(e)}
                                            >
                                                Save
                                            </Button>
                                        </>
                                }

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default AddStructureLevelModal;
