

import React from "react";
export const HistoryIcon = ({ color, ...props }: { color: string, props?: any }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
<path d="M12 21C9.7 21 7.69567 20.2373 5.987 18.712C4.27833 17.1867 3.29933 15.2827 3.05 13H5.1C5.33333 14.7333 6.10433 16.1667 7.413 17.3C8.72167 18.4333 10.2507 19 12 19C13.95 19 15.6043 18.3207 16.963 16.962C18.3217 15.6033 19.0007 13.9493 19 12C19 10.05 18.3207 8.39567 16.962 7.037C15.6033 5.67833 13.9493 4.99933 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H9V10H3V4H5V6.35C5.85 5.28333 6.88767 4.45833 8.113 3.875C9.33833 3.29167 10.634 3 12 3C13.25 3 14.421 3.23767 15.513 3.713C16.605 4.18833 17.555 4.82967 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7633 9.57833 21.0007 10.7493 21 12C21 13.25 20.7623 14.421 20.287 15.513C19.8117 16.605 19.1703 17.555 18.363 18.363C17.5543 19.171 16.6043 19.8127 15.513 20.288C14.4217 20.7633 13.2507 21.0007 12 21ZM14.8 16.2L11 12.4V7H13V11.6L16.2 14.8L14.8 16.2Z" 
fill={color}
/>
</svg>


);
