import React from "react";
export const StructureLevelIcon = ({ color, ...props }: { color: string, props?: any }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M2 2H11V6H9V4H4V9H6V11H2V2ZM22 13V22H13V18H15V20H20V15H18V13H22ZM8 8H16V16H8V8Z" 
        fill={color}
    />
    </svg>

)